import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import LocationIcon from '../images/location.svg';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import strings from '../utils/locals/languages';

let DefaultIcon = L.icon({
    iconUrl: LocationIcon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;

const OpenStreetMap = ({ formik }) => {
    const [position, setPosition] = useState(null);

    const getLocationDetails = async (lat, lng) => {
        try {
            const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`);
            if (!response.ok) {
                throw new Error('Failed to fetch address');
            }
            const data = await response.json();

            formik.setFieldValue('location.city', data.address.city_district || data.address.village || data.address.city || data.address.town || data.address.municipality || data.address.suburb || 'N/A');
            formik.setFieldValue('location.country', data.address.country);
            formik.setFieldValue('location.district', data.address.state_district || data.address.subdistrict || data.address.state || data.address.region);
            formik.setFieldValue('location.neighbourhood', data.address.neighbourhood || 'Not available');
            formik.setFieldValue('location.openAddress', data.address.display_name || 'Not available');
        } catch (error) {
            console.error(error);
        }
    };

    function MyComponent() {
        const map = useMapEvents({
            click(e) {
                getLocationDetails(e.latlng.lat, e.latlng.lng);
                setPosition([e.latlng.lat, e.latlng.lng]);
                formik.setFieldValue('location.geoPoints.coordinates[0]', e.latlng.lng);
                formik.setFieldValue('location.geoPoints.coordinates[1]', e.latlng.lat);
            },
            locationfound(e) {
                getLocationDetails(e.latlng.lat, e.latlng.lng);
                setPosition([e.latlng.lat, e.latlng.lng]);
                formik.setFieldValue('location.geoPoints.coordinates[0]', e.latlng.lng);
                formik.setFieldValue('location.geoPoints.coordinates[1]', e.latlng.lat);
            },
        });
        return null;
    }

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const { latitude, longitude } = position.coords;
                setPosition([latitude, longitude]);
                formik.setFieldValue('location.geoPoints.coordinates[0]', longitude);
                formik.setFieldValue('location.geoPoints.coordinates[1]', latitude);

                try {
                    const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`);
                    if (!response.ok) {
                        throw new Error('Failed to fetch address');
                    }
                    const data = await response.json();
                    formik.setFieldValue('location.city', data.address.city_district || data.address.village || data.address.city || data.address.town || data.address.municipality || data.address.suburb || 'N/A');
                    formik.setFieldValue('location.country', data.address.country);
                    formik.setFieldValue('location.district', data.address.state_district || data.address.subdistrict || data.address.state || data.address.region);
                    formik.setFieldValue('location.neighbourhood', data.address.neighbourhood || 'Not available');
                    formik.setFieldValue('location.openAddress', data.address.display_name || 'Not available');
                } catch (error) {
                    console.log(error);
                }
            }, (error) => {
                setPosition([41.2995, 69.2401]);
            });
        }
    }, []);

    return (
        <div style={{ height: "400px" }}>
            <div className="ml-2 text-gray-700 text-sm font-bold py-1">{strings.map}</div>
            {position && (
                <MapContainer center={position} zoom={13} style={{ height: "400px" }}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.egasi.net">Egasi.net</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={position} draggable={true} onDragend={(e) => console.log(e)}>
                        <Popup>
                            {strings.yourCurrentLocation}
                        </Popup>
                        <MyComponent />
                    </Marker>
                </MapContainer>
            )}
        </div>
    );
};

export default OpenStreetMap;
