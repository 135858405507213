import React, { useState } from 'react';
import apiClient from '../../utils/apiClient';
import { ClipLoader } from 'react-spinners';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import strings from '../../utils/locals/languages';
import Cookies from "js-cookie";
import { generateOrderNumber } from '../../utils/generateOrderNumber';


const PackageCard = ({ package: pkg, buy, isEditOrCreate, data }) => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const handlePayment = async (pkg) => {

        if (pkg.price === 0) {
            createAd(pkg)
        } else {

            sessionStorage.setItem('selectedPackage', JSON.stringify(pkg));

            const SERVICE_ID = process.env.REACT_APP_PAYMENT_SERVICE_ID
            const MERCHANT_ID = process.env.REACT_APP_PAYMENT_MERCHANT_ID
            const AMOUNT = pkg.price
            const TRANSACTION_PARAM = await generateOrderNumber()
            const paymentUrl = `https://my.click.uz/services/pay/?service_id=${SERVICE_ID}&merchant_id=${MERCHANT_ID}&amount=${AMOUNT}&transaction_param=${TRANSACTION_PARAM}&
            &return_url=https://www.egasi.net/select-package&card_type=humo`;
            window.location.href = paymentUrl;
        }

    };

    const handleReturn = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const status = queryParams.get('payment_status');

        if (status === 2) {
            try {
                const storedPackage = JSON.parse(sessionStorage.getItem('selectedPackage'))

                await buyPackage(storedPackage)

                sessionStorage.removeItem('selectedPackage');

            } catch (error) {
                toast.error("Payment was unsuccessfull")
            }
        } else {
            toast.error("Payment was unsuccessfull")
        }
    };

    window.onload = handleReturn;

    const buyPackage = async (selectedPackage) => {
        setLoading(true)
        try {
            const response = await apiClient
                .post(`/v1/user/buy-package`, {
                    packageId: selectedPackage._id,
                    title: selectedPackage.title,
                    price: selectedPackage.price,
                    text: selectedPackage.text,
                    numberOfAds: selectedPackage.numberOfAds,
                    currency: selectedPackage.currency
                })

            if (response.data.success === true) {
                toast.success(response.data.message)
                createAd(selectedPackage)
            } else {
                toast.error(response.data.message)
            }


        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    const createAd = async (selectedPackage) => {

        try {
            setLoading(true); // Start the loading stat
            if (data.basicInformation.area) {
                await apiClient.post("v1/adds/create-realestate-adds", data);
            } else {
                await apiClient.post("v1/adds/create-vehicle-adds", data);
            }
            toast.success(`${strings.adCreateMsg}`);
            await updateUserAdsCount(selectedPackage);
            sessionStorage.removeItem("isEditOrCreate");
            sessionStorage.removeItem("vehicleData");
            navigate('/');
        } catch (err) {
            toast.error(isEditOrCreate === "edit" ? `${strings.adUpdateErrorMsg}` : `${strings.adCreateErrorMsg}`);
        } finally {
            setLoading(false); // End the loading state
        }
    };


    const updateUserAdsCount = async (selectedPackage) => {
        try {
            await apiClient
                .put(`/v1/user/update-ads-count`, {
                    packageId: selectedPackage.packageId,
                })

        } catch (error) {
        }
    }


    return (
        <div className="bg-gray-100 shadow-lg h-[340px] w-80 rounded-md flex flex-col items-center ">
            <div className="bg-[rgb(243,117,33)] rounded-t-lg text-white w-full text-center py-2 text-lg font-bold">{pkg.title || 'New Plan'}</div>
            <div className="flex-1 my-4 w-[90%] text-base">{pkg.text.slice(0, 80)}</div>
            <div className="text-xl flex w-[90%] justify-between mb-2">
                <div>{buy ? strings.numberOfAds : strings.remainingAds}</div>
                <div>{pkg.numberOfAds}</div>
            </div>
            <div className="text-xl flex w-[90%] justify-between mb-2">
                <div>{strings.price}</div>
                <div>{pkg.currency}{pkg.price}</div>
            </div>
            <button className="bg-[#F37521] text-white py-2 px-4 rounded-full mb-4 mt-4 hover:bg-[#D3621E]"
                onClick={() => {
                    if (buy) {
                        handlePayment(pkg)
                    } else {
                        setLoading(true)
                        createAd(pkg)
                    }
                }}
            >
                {loading ? <ClipLoader size={20} color='#fff' /> : 'Proceed'}
            </button>

        </div>
    );
};

export default PackageCard;
