import React from 'react'
import CreatedAdVehicle2 from './CreateAd'
import { useLocation } from 'react-router-dom';
import AppBar from "../../components/AppBar";

const CreateVehicleAd = () => {
  const location = useLocation();
  const id = location?.state?.id;
  return (
    <div>
      <AppBar id='' createPage='' />
      <CreatedAdVehicle2 id={id} />
    </div>
  )
}

export default CreateVehicleAd