import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useNavigate } from 'react-router-dom';
import icon from '../../images/redDot.svg';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { handleRealEstateClick } from '../../services/handlers';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;


const OpenStreetMap = ({ geopoints }) => {

  const navigate = useNavigate()


  return (
    <div style={{ height: "400px", width: '100%' }}>
      {geopoints && (
        <MapContainer center={geopoints[0].coordinates} zoom={13} style={{ height: "400px", width: '100%' }}>
          <TileLayer
            attribution='&copy; <a href="https://www.egasi.net">Egasi.net</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {geopoints.map((point, index) => (
            <Marker key={index} position={point.coordinates}
              onClick={() => {
                handleRealEstateClick(navigate, point.item._id, point.item.basicInformation.title, point.item.basicInformation.category.title, point.item.basicInformation.brand.title, point.item.location.city, point.item.location.district)

              }}
            >
              <Tooltip permanent direction="top">
                <div >
                  <p>{point.price}</p>
                </div>
              </Tooltip>
            </Marker>
          ))}
        </MapContainer>
      )}
    </div>
  );
};

export default OpenStreetMap;
