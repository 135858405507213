import React from 'react';
import AppBar from '../../components/AppBar';
import Plans from './Plans';
import Footer from '../../components/Footer';
import strings from '../../utils/locals/languages';

const Packages = () => {
    return (
        <div className="container flex flex-col min-h-screen">
            <AppBar />
            <div className="flex-grow">
                <Plans />
            </div>
            <Footer strings={strings} />
        </div>
    );
};

export default Packages;
