
import React from 'react'
import Favorite from './Favorite'
import AppBar from "../../components/AppBar";
import Footer from '../../components/Footer';
import strings from '../../utils/locals/languages';

const Fav = () => {
  return (
    <div className="container flex flex-col min-h-screen">
      <AppBar id='' createPage='' />

      <div className='flex-grow'>

        <Favorite />

      </div>

      <div>
        <Footer strings={strings} />
      </div>
    </div>
  )
}

export default Fav