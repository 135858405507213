import React, { useState, useEffect } from "react";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { ClipLoader } from "react-spinners";
import calendar from "../../images/calendar.svg";
import year from "../../images/yeear.svg";
import engineSize from "../../images/engine.svg";
import strings from '../../utils/locals/languages';
import apiClient from '../../utils/apiClient';
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { handleCarClick } from "../../services/handlers";

const SimilarAds = ({ innerdetails }) => {
    let userPhoneNumber;
    let userEmail;

    const user = Cookies.get("userDetails");

    if (user) {
        try {
            const userDetails = JSON.parse(user);

            userPhoneNumber = userDetails?.phone;
            userEmail = userDetails?.email;


        } catch (error) {
        }
    } else {
    }

    const [recommendedData, setRecommendedData] = useState([]);
    const [recommendedDataLoading, setRecommendedDataLoading] = useState(true);
    const navigate = useNavigate()
    const [userId, setUserId] = useState("");

    const addToFavoritesGeneral = (event, adId) => {

        apiClient
            .get(`/v1/user/fav-unfav/${adId}`)
            .catch((err) => { });
    };

    const searchFilter = () => {
        setRecommendedDataLoading(true);
        apiClient
            .get(
                `/v1/adds/search/?section=638f1fd34cb7c29ec6ad4bd6&language=${strings._language}&purpose=${innerdetails?.purpose}
            `
            )
            .then((response) => {
                setRecommendedData(response.data.data);
                setRecommendedDataLoading(false);
            })
            .catch((err) => { });;
    };

    useEffect(() => {
        searchFilter();
    }, [strings._language]);



    return (
        <div className="col-12 col-md-6 col-lg-4  hide-scrollbar ">
            <h6
                className="font-bold text-center uppercase "
            >
                {strings.similarAds}
            </h6>
            <div className="h-[1060px] w-[103%] overflow-y-auto overflow-x-visible hide-scrollbar">
                {recommendedDataLoading ? (
                    <div className="container flex justify-end">
                        <div>
                            <ClipLoader
                                loading={recommendedDataLoading}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>
                    </div>
                ) : (
                    recommendedData.slice(0, 8).map((item, index) => (
                        <div
                            className="rounded-lg ml-2 shadow-md bg-white flex  mt-[10px] border border-gray-50 "
                            key={index}
                            style={{
                                borderRadius: 12,
                                height: 140,
                            }}
                        >
                            <div
                                onClick={() => {
                                    handleCarClick(navigate, item._id, item.basicInformation.title, item.basicInformation.category.title, item.basicInformation.brand.title, item.basicInformation.model.title, item.location.city, item.location.district, item.basicInformation.KM)
                                    window.location.reload();
                                }}
                                className="r round w-1/2 cursor-pointer"
                            >
                                <img
                                    src={item.imageUrl[0]}
                                    style={{
                                        // borderTopLeftRadius: "24px",
                                        // borderBottomLeftRadius: "24px",
                                        height: 140,
                                        width: '100%',
                                        display: "block",
                                    }}
                                    className="rounded-tl-lg rounded-bl-lg"
                                />
                            </div>
                            <div
                                className=" p-2 pt-3 w-1/2  overf"
                            >
                                <div
                                    className="d-flex justify-content-between"
                                >
                                    <h6
                                        className=" text-truncate text-sm font-medium"
                                    >
                                        {item.basicInformation.title}
                                    </h6>

                                    {!item?.fav?.includes(userId) ? (
                                        <FavoriteBorderOutlinedIcon
                                            className=" items-content-end w-12"
                                            style={{
                                                right: "8px",
                                                color: "#F37521",
                                                backgroundColor: "white",
                                                borderRadius: 100,
                                                cursor: "pointer",
                                                padding: 3,
                                                top: 8,
                                            }}
                                            onClick={(event) => {
                                                if (user) {
                                                    addToFavoritesGeneral(event, item._id);
                                                }
                                            }}
                                        />
                                    ) : (
                                        <FavoriteOutlinedIcon
                                            className=" items-content-end w-12 opacity-100"
                                            style={{
                                                right: "8px",
                                                color: "red",
                                                backgroundColor: "white",
                                                cursor: "pointer",
                                                borderRadius: 100,
                                                padding: 3,
                                                top: 8,
                                            }}
                                            onClick={(event) => {
                                                if (user) {
                                                    addToFavoritesGeneral(event, item._id);
                                                }
                                            }}
                                        />
                                    )}
                                </div>

                                <p
                                    style={{
                                        fontSize: "10px",
                                        opacity: "0.3",
                                        whiteSpace: "nowrap", // Prevent text from wrapping
                                        overflow: "hidden", // Hide any overflow
                                        textOverflow: "ellipsis", // Add ellipsis (...) for truncated text
                                    }}
                                >
                                    {item?.basicInformation?.description?.slice(0, 6)}
                                </p>

                                <h5
                                    style={{
                                        color: "#F37521",
                                        // width: "200px",
                                        fontSize: "14px",
                                        fontWeight: "700",
                                    }}
                                >
                                    {item.basicInformation.price.currency}{" "}
                                    {Number(item?.basicInformation?.price?.price).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                </h5>

                                <div
                                    className="lr flex gap-3 opacity-80"
                                    style={{ marginTop: "20px" }}
                                >
                                    {item.basicInformation.details.map((item) => {
                                        if (
                                            item.fieldName === "Year" ||
                                            item.fieldName === "год" ||
                                            item.fieldName === "Yil"
                                        ) {
                                            return (
                                                <div className="d- flex gap-1">
                                                    <img
                                                        src={year}
                                                        alt=""
                                                        className="h-[10px] w-[10px]"
                                                        style={{}}
                                                    />
                                                    <div
                                                        style={{
                                                            fontSize: "8px",
                                                            // paddingLeft: "10px",
                                                            // width: "80px",
                                                        }}
                                                    >
                                                        {item.value}
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}

                                    {item.basicInformation.details.map((item) => {
                                        if (
                                            item.fieldName === "Engine Size" ||
                                            item.fieldName === "Размер двигателя" ||
                                            item.fieldName === "Dvigatel hajmi"
                                        ) {
                                            return (
                                                <div className="d-fl flex gap-1">
                                                    <img
                                                        className="h-[10px] w-[10px]"
                                                        src={engineSize}
                                                        alt=""
                                                    // style={{ marginTop: 3 }}
                                                    />
                                                    <div
                                                        style={{
                                                            fontSize: "8px",
                                                        }}
                                                    >
                                                        {item.value}
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}

                                    <div className="d-fl flex gap-1">
                                        <img
                                            src={calendar}
                                            className="h-[10px] w-[10px]"
                                            alt=""
                                            style={{}}
                                        />
                                        <p
                                            className='text-[8px]'
                                        >
                                            {item.basicInformation.KM} Km
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    ))
                )}
            </div>
        </div>
    )
}

export default SimilarAds