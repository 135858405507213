import React from 'react'
import Faq from './Faqs'
import AppBar from "../../components/AppBar";

const Faqs = () => {
  return (
    <>
      <AppBar id='' createPage='' />
      <Faq />
    </>
  )
}

export default Faqs