import React from "react";
import Body from "./Body";
import {useParams } from "react-router-dom";
import AppBar from "../../components/AppBar";


const Index = () => {
const {id} = useParams()

  return (
    <div>
      <AppBar id="638f25154cb7c29ec6ad4bfe" createPage='/create-realestate-ad' />
      <Body id={id} />
      
    </div>
  )
}

export default Index