import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDAOFpbe8T1DhJOQWu-FpqgPmK6xJG_4V0",
  authDomain: "egasi-net.firebaseapp.com",
  projectId: "egasi-net",
  storageBucket: "egasi-net.appspot.com",
  messagingSenderId: "487476637537",
  appId: "1:487476637537:web:914d5f43294cdb68719f20",
  measurementId: "G-KFHX2YNVR7"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const analytics = getAnalytics(app);

export const getFcmToken = async () => {
  try {
    const currentToken = await getToken(messaging, { vapidKey: 'BFz9J94ViewzE49_Z0qmlQ8E2II5SnQX6sr3OPMbmYF5nRLcKyucvYrqlcQoqcttiyBY86T9gowlY7pGE4vLeRw' });

    if (currentToken) {
      return currentToken
    } else {
      return null
    }
  } catch (err) {
    return null
  }
}


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });