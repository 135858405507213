import React from 'react'
import CreatedeAd1 from './CreateAd'
import { useLocation } from 'react-router-dom';
import AppBar from "../../components/AppBar";

const CreateRealEstateAd = () => {
  const location = useLocation();
  const id = location.state.id;
  return (
    <div>
       <AppBar id='' createPage='' />
        <CreatedeAd1 id={id} />
    </div>
  )
}

export default CreateRealEstateAd