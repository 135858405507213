import { createSlice } from '@reduxjs/toolkit';

export const languageSlice = createSlice({
  name: 'language',
  initialState: 'en', // default language is English
  reducers: {
    setLanguage: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
