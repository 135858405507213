export const handleCarClick = (navigate, id, title, category, brand, model, city, distt, km) => {
    const cleanedDescription = title?.replace(/\s+/g, '-').toLowerCase()
    const cleanedCategory = category?.replace(/\s+/g, '-').toLowerCase()
    const cleanedBrand = brand?.replace(/\s+/g, '-').toLowerCase()
    const cleanedModel = model?.replace(/\s+/g, '-').toLowerCase()
    const cleanedDistt = distt?.replace(/\s+/g, '-').toLowerCase()
    const cleanedCity = city?.replace(/\s+/g, '-').toLowerCase()

    navigate(`/vehicle-details/${cleanedCategory}-${cleanedBrand}-${cleanedModel}-${cleanedDescription}-${km}-${cleanedCity}-${cleanedDistt}?id=${id}`);
  };


export  const handleRealEstateClick = (navigate, id, title, category, brand, city, district) => {
    const cleanedDescription = title?.replace(/\s+/g, '-').toLowerCase()
    const cleanedCategory = category?.replace(/\s+/g, '-').toLowerCase()
    const cleanedBrand = brand?.replace(/\s+/g, '-').toLowerCase()
    const cleanedCity = city?.replace(/\s+/g, '-').toLowerCase()
    const cleanedDistrict = district?.replace(/\s+/g, '-').toLowerCase()

    navigate(`/real-estate-details/${cleanedCategory}-${cleanedBrand}-${cleanedDescription}-${cleanedCity}-${cleanedDistrict}?id=${id}`);
  };