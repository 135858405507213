import React, { useState, useEffect } from 'react'
import Body from './Body'
import AppBar from "../../components/AppBar";
import { useLocation, useParams } from "react-router-dom";
import apiClient from '../../utils/apiClient';
import strings from '../../utils/locals/languages';
import { ClipLoader } from "react-spinners";
import { vehicleId } from '../../utils/baseUrl';
import Cookies from "js-cookie";

const CarInnerDetails = () => {



  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id'); // Fetch the id from the query parameters

  const [innerdetails, setInnerDetails] = useState()
  const [isDataLoading, setIsDataLoading] = useState(true)

  const selectedLanguage = Cookies.get("language");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.get(
          `/v1/adds/fetch-ad-details/${id}?language=${selectedLanguage === undefined ? "en" : selectedLanguage}&section=${vehicleId}`
        );
        setInnerDetails(response.data);
      } catch (err) {
      } finally {
        setIsDataLoading(false);
      }
    };
  
    fetchData();
  }, [strings._language, id]);
  

  return (
    <div>
      {
        isDataLoading ? (
          <div class="flex justify-center items-center h-screen">
            <ClipLoader />
          </div>
        ) : (
          <>
            <AppBar id={id} createPage='/create-vehicle-ad' disableLanguage={false} />
            <Body innerdetails={innerdetails} />
          </>
        )
      }
    </div>
  )
}

export default CarInnerDetails;
