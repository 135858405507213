import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import strings from "../../utils/locals/languages";
import NotificationsConvas from "../../components/NotificationsCanvas";
import { useSelector } from "react-redux";

const About = () => {

  const notifications = useSelector(state => state.notifications.notifications);

  return (
    <>
      <div className="container privacy-policy mt-5">
        <div className="row">
          <div className="col-12">
            <h3 className="font-semibold text-base text-center">{strings.about}</h3>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="col-12">
            <h5 className="text-[#F37521] text-sm">Intro</h5>
            <p className="text-justify text-xs">
              EGASI is a platform, where user can search ads related to Real Estate, Vehicle and much more. Also user can add their own ads{" "}
            </p>
          </div>
        </div>
      </div>
      <NotificationsConvas notificationData={notifications} />
    </>
  );
};

export default About;
