import React, { useEffect, useState } from "react";
import paragraph from "../images/paragraph.svg";
import sms from "../images/sms.svg";
import facebook from "../images/facebook.svg";
import Google from "../images/google.svg";
import twitter from "../images/twitter.svg";
import instagram from "../images/instagram.svg";
import phone from "../images/phone.svg";
import { useNavigate } from "react-router-dom";
import { realEstateId, vehicleId } from "../utils/baseUrl";
import apiClient from "../utils/apiClient";
import moment from "moment";

const Footer = ({ strings }) => {
  const navigate = useNavigate()

  const [footerData, setFooterData] = useState(null)

  const getFooterData = async () => {
    try {
      const response = await apiClient.get(`/v1/user/get-footer-data`)
      setFooterData(response.data[0])

    }
    catch (err) {
      setFooterData(null)
    }
  }

  const navigateToClassi = () => {
    window.open('https://www.classiconnect.uz', '_blank');
  }


  useEffect(() => {
    getFooterData()
  }, [])
  return (
    <div>
      {/*footer*/}
      <div className="container mx-auto  mt-5 ">
        <div className=" bg-gray-200 grid lg:grid-cols-3    md:grid-cols-3  sm:grid-cols-1 px-5 pt-4">
          <div className="sm:px-5 md:px-5 lg:md-5">
            <h6 className="font-bold md:mt-0 mt-4" >{strings.contactUs}</h6>
            <div className="flex">
              <img className="me-3" src={paragraph} alt="" />
              <p style={{ marginTop: "20px" }}>
                {footerData?.contactUs?.location}
              </p>
            </div>

            <div className="flex">
              <img className="w-6" src={phone} alt="" />
              <div className="mt-2 ">
                <p className="px-3 mt-2">
                  {footerData?.contactUs?.phone}
                </p>
              </div>
            </div>

            <div className="flex items-center ">
              <img className="sm:w-6 md:w-6 lg:w-6 -mt-1" src={sms} alt="" />
              <div className="mt-2">
                <p className="px-3">{footerData?.contactUs?.email}</p>
              </div>
            </div>
          </div>
          <div className="">
            <h6 className="font-bold md:mt-0 mt-4 ">{strings.quickLinks}</h6>
            <p className="mt-4 cursor-pointer "
              onClick={() => {
                navigate('/')
              }}
            >{strings.home}</p>
            <p className="cursor-pointer"
              onClick={() => {
                navigate('/realstate', {
                  state: {
                    id: realEstateId
                  }
                })
              }}
            >{strings.realEstates}</p>
            <p className="cursor-pointer"
              onClick={() => {
                navigate('/vehicle', {
                  state: {
                    id: vehicleId
                  }
                })
              }}
            >{strings.vehicles}</p>
            <p className="cursor-pointer"
              onClick={() => {
                navigate('/aboutus')
              }}
            >{strings.about}</p>

            <p className="cursor-pointer"
              onClick={() => {
                navigate('/privacy-policy')
              }}
            >{strings.privacyPolicy}</p>
          </div>
          <div className="">
            <h6 className="font-bold md:mt-0 mt-4">{strings.followUs}</h6>

            <div className="flex gap-2 mt-4">
              <a href={footerData?.socialMedia.facebook} target="_blank" rel="noopener noreferrer">
                <img className="w-8" src={facebook} alt="Facebook" />
              </a>

              <a href="https://www.egasi.net" target="_blank" rel="noopener noreferrer">
                <img className="w-8" src={Google} alt="Google" />
              </a>

              <a href={footerData?.socialMedia.twitter} target="_blank" rel="noopener noreferrer">
                <img className="w-8" src={twitter} alt="Twitter" />
              </a>

              <a href={footerData?.socialMedia.instagram} target="_blank" rel="noopener noreferrer">
                <img className="w-8" src={instagram} alt="Instagram" />
              </a>
            </div>

          </div>
        </div>

        <div className="pb-2 bg-gray-200 container mx-auto md:pt-0 pt-6">
          <h6 className="text-center   sm:text-center md:text-center  sm:text-sm">
            © {moment().format('YYYY')} {strings.allRights}. {strings.createdBy}

            <i className="cursor-pointer text-blue-400"
              onClick={() => navigateToClassi()}
            > Classi Connect</i>
          </h6>
        </div>
      </div>
    </div>
  );
};

export default Footer;
