import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import icon from '../../images/redDot.svg';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;


const OpenStreetMap = ({ geopoints }) => {


    return (
        <div style={{ height: "400px", width: '100%' }}>
            {geopoints && (
                <MapContainer center={geopoints[0].coordinates} zoom={13} style={{ height: "400px", width: '100%' }}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.egasi.net">Egasi.net</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={geopoints[0].coordinates} >
                        <Popup>
                            Your current location.
                        </Popup>
                    </Marker>
                </MapContainer>
            )}
        </div>
    );
};

export default OpenStreetMap;

