import React from 'react'
import ReportProblem from './Report'
import AppBar from "../../components/AppBar";

const Report = () => {
  return (
    <>
    <AppBar id='' createPage='' />
    <ReportProblem />
    </>
  )
}

export default Report