import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { baseURL1 } from '../../utils/baseUrl';
import { setLanguage } from "../../app/languageSlice";
import { useDispatch } from "react-redux";
import strings from "../../utils/locals/languages";
import Cookies from "js-cookie";

import { ClipLoader } from 'react-spinners';
import apiClient from '../../utils/apiClient';

const PrivacyPolicy = () => {
  const [profileData, setProfileData] = useState({})
  const [notificationData, setNotificationData] = useState([])
  const [notificationCount, setNotificationCount] = useState(0)

  const [data, setDate] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleLanguageChange = (language) => {
    strings.setLanguage(language); // set the language for the LocalizedStrings instance

    Cookies.set("language", language);
    setTimeout(() => {
      window.location.reload();
    }, 500);
    dispatch(setLanguage(language)); // dispatch an action to update the language in the store
  };

  useEffect(() => {
    const selectedLanguage = Cookies.get("language")

    if (selectedLanguage !== undefined) {
      strings.setLanguage(selectedLanguage)
    }

  }, [])

  const logOutUser = () => {
    Cookies.remove("token");
    Cookies.remove("userDetails");
    window.location.reload();
  };

  useEffect(() => {
    axios.get(`${baseURL1}/v1/adds/privacy`)
      .then((res) => {
        setDate(res.data.privacyPolicy)
        setIsLoading(false)
      })
      .catch((err) => {
      })
  }, [])

  useEffect(() => {
    apiClient
      .get(
        `/v1/user/notification`
      )
      .then((response) => {
        setNotificationCount(response.data.length)
        setNotificationData(response.data)
      })
      .catch((err) => { });
  }, [])

  useEffect(() => {
    const userDetails = Cookies.get("userDetails")
    if (userDetails) {
      const user = JSON.parse(userDetails);
      const {
        name,
        companyName,
        phone,
        email,
        country,
        city,
        logo,
        address,
        image,
        taxNumber,
        officePhone,
        gender,
      } = user;
      const filteredUser = {
        name,
        companyName,
        phone,
        email,
        country,
        city,
        logo,
        address,
        image,
        taxNumber,
        officePhone,
        gender
      };
      setProfileData(filteredUser);
    } else {
      setProfileData('Null')
    }

  }, [])
  return (
    <>

      <div className="container privacy-policy mt-5">
        <div className="row">
          <div className="col-12">
            <h3 className='font-base text-semibold text-center'>{strings.Priv}</h3>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        {
          isLoading ? (
            <div className="container flex justify-center">
              <div>
                <ClipLoader
                  loading={isLoading}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            </div>
          ) : (
            data.map((item) => (
              <div className="row">
                <div className="col-12">
                  <h5 className="text-[#F37521] text-sm ">{item.title}</h5>
                  <div className='w-full break-words	'>
                    <pre className='w-[100%] break-words whitespace-pre-line  font-sans			'>
                      <p className="text-justify text-xs break-words ">
                        {item.text}.{" "}
                      </p>
                    </pre>
                  </div>

                </div>
              </div>
            ))
          )
        }


      </div>

      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasExampleLabel">{strings.notif}</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          {
            notificationData.map((data, index) => (
              <div key={index}
                className={`p-2 ${index % 2 === 0 ? "bg-gray-300" : ""
                  }`}
              >
                <p style={{ fontWeight: 'bold' }}>{index + 1}. {data.title}</p>
                <p>{data.message}</p>
              </div>
            ))
          }
        </div>

      </div>
    </>
  );
}

export default PrivacyPolicy