import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {

    const navigate = useNavigate()

    const redirectToHome = () => {
        navigate('/');
    };


    return (
        <div className="flex items-center justify-center h-screen">
            <div className="text-center">
                <h1 className="text-4xl font-bold mb-4">404 - Not Found</h1>
                <p className="text-lg">The page you are looking for does not exist.</p>
                <p className="text-lg text-orange-500 cursor-pointer "
                    onClick={redirectToHome}
                >Go to home page</p>
            </div>
        </div>
    );
}

export default NotFoundPage;
