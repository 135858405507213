import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  en: {
    all: 'All',
    accoundDeleteSuccess: 'Account deleted successfully!',
    accoundDeleteFailed: 'Failed to delete account. Please try again.',
    deleteAccount: 'Delete Account?',
    areYouSureDeleteAcc: 'Are you sure you want to delete your account? This will also delete all of your ads.',
    ThisAdIsCreatedIn: 'This ad is created in',
    PleaseChangeYourLanguageTo: 'Please change Your language to',
    thenYouCanEdit: 'then you can edit',
    yourCurrentLocation: 'Your current location',
    map: 'Map',
    car: 'Car',
    standardPackage: 'Standard Package',
    remainingAds: 'Remaining Ads',
    numberOfAds: 'Number Of Ads',
    pleaseSelectPlan: 'Please Select a Plan',
    totalAdsRemaining: 'Total Ads Remaining',
    freeAdsRemaining: 'Free Ads Remaining',
    boughtPackages: 'Bought Packages',
    noPackageFound: 'No Package Found',
    otherPackages: 'Other Packages',
    resendCode: 'Resend Code',
    pleaseClickOnSection: 'Please click on the section that you want to create an ad',
    proceedWithFreePlan: 'Proceed with Free Plan',
    packages: 'Packages',
    locationAcces: 'Please allow location access to fetch location details',
    status: "Status",
    active: "Active",
    inActive: "In Active",
    featured: "Featured",
    expired: "Expired",
    pending: "Pending",
    rejected: "Rejected",
    coverPhoto: 'Cover Photo',
    disabledFacilities: 'Disabled Facilities',
    comingSoon: 'Coming Soon!',
    emailVerify: 'Email Verification',
    userVerified: 'User Verified Successfully',
    priceHighest: 'Price Highest First',
    priceLowest: 'Price Lowest First',
    newest: 'Newest First',
    oldFirst: 'Old First',
    alpAZ: 'Alphabet (A-Z)',
    alpZA: 'Alphabet (Z-A)',
    noCategory: 'No Category',
    type: 'Type',
    house: 'House',
    addLogo: "Add Logo",
    recommendedRealEstates: "Recommended Real Estates",
    recommendedVehicles: "Recommended vehicles",
    topCities: "Top Cities",
    whatDo: "What you can do?",
    findPlace: "Find your dream place",
    findPlaceText:
      "Finding your dream place for a property can be a daunting task, but with the right tools and mindset, it can also be an exciting and rewarding experience. Start by defining your priorities, whether that be proximity to amenities, a certain style of home, or a specific location. Utilize online resources to browse properties and gather information about neighborhoods and communities. Consider working with a trusted real estate agent who can provide guidance and support throughout the process. With patience and persistence, you can find the perfect place to call home.",
    findVehicle: "Find your dream vehicle",
    findVehicleText:
      "Finding your dream vehicle can be an exhilarating experience, but it can also be overwhelming with the vast array of options available. Begin by determining your needs and preferences, such as the type of vehicle, fuel efficiency, and features that are important to you. Research the different makes and models available, and read reviews from experts and owners alike. Take test drives to get a feel for the vehicles and see if they meet your expectations. Consider your budget and financing options to determine what is feasible for you. With careful consideration and research, you can find the perfect vehicle to suit your lifestyle and needs",
    buyRent: "Rent, Buy & Sell",
    buyRentText:
      "Renting, buying, and selling properties are the three primary ways individuals and families engage in the real estate market. Renting a property allows for more flexibility and less commitment, while buying a property provides the opportunity for ownership and investment in a long-term asset. Selling a property involves marketing the property and negotiating with potential buyers to secure a sale. Each option has its own advantages and disadvantages, depending on individual circumstances and goals. Whether you're looking to rent, buy, or sell a property, it's important to work with a reputable real estate agent who can guide you through the process and help you make informed decisions.",
    listAdd: "List your Ad",
    listAddText:
      "Listing your property for rent or your vehicle for sale on a property site is a simple and effective way to reach a wider audience and increase your chances of finding a suitable tenant or buyer. To create a compelling listing, make sure to include clear photos of the property or vehicle, a detailed description of its features and amenities, and accurate pricing information. Highlight any unique selling points that may set your property or vehicle apart from the competition. Respond promptly to inquiries and be prepared to show the property or vehicle to potential renters or buyers. With a well-crafted listing and a proactive approach, you can successfully rent out your property or sell your vehicle on a property site",
    getApp: "Get the EGASI App",
    getAppP:
      "Using our app, you can buy and rent property faster and more efficiently",
    ourP: "OUR PARTNERS",
    searchSomething: "Search something ...",
    home: "Home",
    login: "Login",
    logout: "Logout",
    createAd: "Create Ad",
    rightPlace: "THE RIGHT PLACE TO FIND YOUR PERFECT PLACE",
    rightVehicle: "THE RIGHT PLACE TO FIND PERFECT CARS",
    categories: "Categories",
    residentials: "Residentials",
    flats: "Flats",
    plots: "Plots",
    agriculture: "Agriculture",
    adInfo: "Ad Information",
    reveiews: "Preview",
    pkg: "Package",
    selectPurpose: "Select Purpose",
    selectCat: "Select Category",
    selectSubCat: "Select Sub Category",
    selectBrand: "Select Brand",
    selectModel: "Select Model",
    select: "Select",
    basicInfo: "Basic Information",
    title: "Title",
    desc: "Description",
    price: "Price",
    yes: "Yes",
    no: "No",
    contactInfo: "Contact Information",
    namesurename: "name and surname",
    wtp: "Whatsapp",
    teleg: "Telegram",
    call: "Call",
    sms: "SMS",
    locationSettings: "Location Setting",
    country: "Country",
    city: "City",
    distt: "District",
    internalFeatures: "Internal Features",
    extFeatures: "External Features",
    safetyFeatures: "Safety Features",
    disclose: "I do not want to disclose",
    addManually: "Add Manually",
    itemName: "Item Name",
    bodyParts: "Body Parts",
    original: "original",
    painted: "painted",
    replaced: "replaced",
    frontBumper: "Front Bumper",
    engineBonnet: "Engine Bonnet",
    ceiling: "Ceiling",
    rightFrontFendor: "Right Front Fendor",
    rightFrontDoor: "Right Front Door",
    rightRearDoor: "Right Rear Door",
    leftFrontFendor: "Left Front Fendor",
    leftRearDoor: "Left Rear Door",
    leftRearFendor: "Left Rear Fendor",
    rightRearFendor: "Right Rear Fendor",
    tailgate: "Tail gate",
    rearBumper: "Rear Bumper",
    leftFrontDoor: "Left Front Door",
    continue: "Continue",
    loading: "Loading",
    neigh: "Neighborhood",
    complex: "Complex",
    openadd: "Open Address",
    accessToTr: "Access to transportation",
    fac: "Facilities for disabled",
    location: "location",
    purpose: "Purpose",
    category: "Category",
    brand: "Brand",
    model: "Model",
    rent: "Rent",
    sale: "Sale",
    search: "Search",
    enterCity: "Enter City...",
    upload: "Upload",
    image: "Image",
    video: "Video",
    images: "Images",
    videos: "Videos",
    add: "Add",
    uploading: "Uploading...",
    accept: "I accept the",
    adPosting: "Ad Posting Rules",
    next: "Next",
    subCate: "Sub Category",
    details: "Details",
    area: "Area",
    myProfile: "My Profile",
    AdManagement: "Ad Management",
    balance: "Balance",
    fav: "Favorites",
    messages: "Messages",
    lang: "Language",
    Priv: "Privacy Policy",
    faqs: "FAQ's",
    reportProb: "Report Problem",
    about: "About Us",
    norec: "No Record Found",
    realEstates: "Real Estates",
    vehicles: "Vehicles",
    my: "My",
    profile: "Profile",
    personal: "Personal",
    info: "Information",
    name: "Name",
    email: "Email",
    phone: "Phone",
    taxNbr: "Tax Number",
    address: "Address",
    update: "Update",
    user: "User",
    write: "Write",
    screenshot: "Screenshot",
    fill: "Fill out the field!",
    typeHere: "Type here",
    pleaseSignIn: "Please Sign in to continue",
    forgotPassword: "Forgot Password",
    withNumber: "Sign In with Number",
    alreadyHave: "Already Have an Account?",
    signUp: "Sign Up",
    vehicleFor: "Vehicle for",
    reportad: "Report this ad",
    // moreDetails: "More Details",
    moreDetails: "Details",
    features: "Features",
    realestateFor: "Realestate for",
    delete: "Delete",
    areYouSure: "Are you sure, do you want to delete the selected ad?",
    gross: "Gross",
    net: "Net",
    editAd: "Edit Ad",
    contactUs: "Contact Us",
    quickLinks: "Quick Links",
    followUs: "Follow Us",
    allRights: "All rights reserved",
    createdBy: "Created By",
    enableDisable: "Enable/Disable",
    imageUpload: "Image Uploaded SuccessFully!",
    imageUploadFailed: "Failed to upload image!",
    videoUpload: "Video Uploaded SuccessFully!",
    videoUploadFailed: "Failed to upload Video!",
    acceptRules: "Please accept the ad Posting Rules before preceding",
    titleErrorMsgOne: "Title must be at least 5 characters",
    titleErrorMsgTwo: "Title is required",
    descErrorMsgOne: "Description must be at least 10 characters",
    descErrorMsgTwo: "Description is required",
    currencyError: "Currency is required",
    priceErrorOne: "Price must be greater than 0",
    priceErrorTwo: "Price is required",
    required: "Required",
    grossAreaMsgOne: "Gross Area must be greater than 0",
    grossAreaMsgTwo: "Gross Area is required",
    netAreaMsgOne: "Net Area must be greater than 0",
    netAreaMsgTwo: "Net Area is required",
    loginSuccess: "Logged in successfully!",
    loginWarn: "Please login first",
    adCreateMsg: "Added SuccessFully!",
    adCreateErrorMsg: "Failed to create the ad!",
    adUpdateMsg: "Updated SuccessFully!",
    adUpdateErrorMsg: "Failed to update the ad!",
    profileUpdateSuccess: "Profile Updated Successfully!",
    profileUpdateError: "Failed to update Profile",
    for: "For",
    bodyPartsErr: "Please select all body parts",
    contactError: "Please select any of the contact information",
    imgErr: "Please select atleast one Image",
    vidErr: "Please select a Video",
    requiredFields: "Please select all the required fields",
    submit: "Submit",
    reportAdded: "Report submitted successfully!",
    failedToSubmitReport: "Failed to submit your report, Try Again",
    adName: "Ad Name",
    adPub: "Ad Publisher",
    reportType: "Report Type",
    nudity: "Nudity",
    spam: "Spam",
    unAuthSales: "Unauthorised sales",
    violence: "Violence",
    falseInfo: "False Info",
    somethingElse: "Something else",
    notif: "Notifications",
    invalidEmail: "Invalid email address",
    addedToFav: "Added to Favorites",
    failedToAdd: "Failed, try again",
    pleaseSelectRecoveryMethod: "Please select a method for your password recovery",
    signIn: "Sign In",
    dontHave: "Don't Have an Account?",
    signinWithEmail: "Sign In with Email",
    createAcc: "Create Account",
    pleaseSelectAccMethod: "Please select a method from which you want to create an account",
    emailAddress: "Email Address",
    mobileNumber: "Mobile Number",
    pleaseCreateTo: "Please create account to continue",
    pass: "Password",
    confirmPass: "Confirm Password",
    readAndAgree: "I have read and agree to the",
    privacyPolicy: "Privacy Policy",
    and: "and",
    termsOfUse: "Terms of Use",
    ind: "Individual",
    corporate: "Corporate",
    companyName: "Company Name",
    taxNumber: "Tax Number",
    officeNumber: "Office Number",
    officeAddress: "Office Address",
    enterTheCode: "Enter The Code",
    codeThatReceived: "Enter the code that you received through SMS",
    verify: "Verify",
    verified: "Verified Successfully!",
    verificatonMsg: "Your code has been verified Successfully. Please continue.",
    dailyRent: "Daily Rent",
    sortBy: "Sort by",
    noDuplicate: "No Duplicate Entry is allowed",
    similarAds: "Similar Ads",
    AdNumber: "Ad Number",
    listingDate: "Listing Date",
    series: "Series",
    year: "Year",
    fuel: "Fuel",
    Gear: "Gear",
    bodyType: "Body Type",
    enginePower: "Enginer Power",
    externalFeatureError: "Please Select an External Feature or Select it to not disclose",
    safetyFeatureError: "Please Select a Safety Feature or Select it to not disclose",
    internalFeatureError: "Please Select an Internal Feature or Select it to not disclose",
    pleaseEnterEmail: "Please enter the email to receive the code",
    pleaseEnterNumber: "Please enter Your Phone Number",
    sendRequestEmail: "Send Request Email",
    signUpSuccess: "User Registered Successfully!",
    failedToCreateUser: "Failed to create user",
    checkEmail: "Check your email and enter the code to continue",
    sendRequest: "Send Request",
    resetpassword: "Reset Password",
    pleaseEnterNewPassword: "Please enter your new password",
    passwordUpdated: "Your password has been updated",
    filters: "Filters",
    mapView: "Map View",
    viewType: "View Type",
    locationFilter: "Location Filter",
    searchNearby: "Search Nearby",
    priceRange: "Price Range",
    areaRange: "Area Range",
    bedrooms: "Bedrooms",
    bathrooms: "Bathrooms",
    searchHere: 'Search...',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    linkCopied: "Ad Link Copied",
    otpSent: 'OTP sent successfully!',
    emailIsRequired: "Email is required",
    phoneIsRequired: "Phone Number is required",
    verificationCodeRequired: "Verification code is required",
  },
  ru: {
    all: 'Все',
    accoundDeleteSuccess: 'Аккаунт успешно удален!',
    accoundDeleteFailed: 'Не удалось удалить аккаунт. Пожалуйста, попробуйте еще раз.',
    deleteAccount: 'Удалить аккаунт?',
    areYouSureDeleteAcc: 'Вы уверены, что хотите удалить аккаунт? Это также удалит все ваши объявления.',
    ThisAdIsCreatedIn: 'Это объявление создано в',
    PleaseChangeYourLanguageTo: 'Пожалуйста, измените свой язык на',
    thenYouCanEdit: 'тогда ты сможешь редактировать',
    yourCurrentLocation: 'Ваше текущее местоположение',
    map: 'Карта',
    car: 'Машина',
    standardPackage: 'Стандартный пакет',
    remainingAds: 'Оставшиеся объявления',
    numberOfAds: 'Количество объявлений',
    pleaseSelectPlan: 'Пожалуйста, выберите план',
    totalAdsRemaining: 'Всего объявлений осталось',
    freeAdsRemaining: 'Осталось бесплатных объявлений',
    boughtPackages: 'Купленные пакеты',
    noPackageFound: 'Пакет не найден',
    otherPackages: 'Другие пакеты',
    resendCode: 'Отправить код еще раз',
    pleaseClickOnSection: 'Пожалуйста, нажмите на раздел, в котором вы хотите создать объявление.',
    proceedWithFreePlan: 'Продолжить бесплатный план',
    packages: 'Пакеты',
    locationAcces: `Разрешите доступ к местоположению, чтобы получить сведения о местоположении.`,
    status: "Положение дел",
    active: "Активный",
    inActive: "Неактивный",
    featured: "Рекомендуемые",
    expired: "Истекший",
    pending: "В ожидании",
    rejected: "Отклоненный",
    coverPhoto: 'Фото на обложке',
    disabledFacilities: 'Удобства для инвалидов',
    comingSoon: 'Вскоре!',
    emailVerify: 'Подтверждение по элетронной почте',
    userVerified: 'Пользователь успешно проверен',
    priceHighest: 'Сначала дорогие',
    priceLowest: 'Сначала дешевые',
    newest: 'Сначала новые',
    oldFirst: 'Сначала старые',
    alpAZ: 'По алфавиту (А-Я)',
    alpZA: 'По алфавиту (Я-А)',
    noCategory: 'Нет категории',
    type: 'Тип',
    house: 'Дом',
    addLogo: "Добавить логотип",
    recommendedRealEstates: "Рекомендуемая недвижимость",
    recommendedVehicles: "Рекомендуемые автомобили",
    topCities: "Лучшие города",
    whatDo: "Что ты можешь сделать?",
    findPlace: "Найдите место своей мечты",
    findPlaceText:
      "Найти место своей мечты для недвижимости может быть сложной задачей, но с правильными инструментами и мышлением это также может быть захватывающим и полезным опытом. Начните с определения ваших приоритетов, будь то близость к удобствам, определенный стиль дома или конкретное местоположение. Используйте онлайн-ресурсы для просмотра свойств и сбора информации о районах и сообществах. Подумайте о работе с доверенным агентом по недвижимости, который может предоставить рекомендации и поддержку на протяжении всего процесса. Проявив терпение и настойчивость, вы сможете найти идеальное место, которое можно назвать домом.",
    findVehicle: "Найдите автомобиль своей мечты",
    findVehicleText:
      "Поиск автомобиля своей мечты может быть волнующим опытом, но он также может быть ошеломляющим из-за огромного количества доступных вариантов. Начните с определения ваших потребностей и предпочтений, таких как тип транспортного средства, эффективность использования топлива и функции, которые важны для вас. Изучите доступные марки и модели и прочитайте отзывы как экспертов, так и владельцев. Совершите тест-драйв, чтобы получить представление об автомобилях и посмотреть, соответствуют ли они вашим ожиданиям. Рассмотрите свой бюджет и варианты финансирования, чтобы определить, что вам подходит. После тщательного рассмотрения и исследований вы сможете найти идеальный автомобиль, соответствующий вашему стилю жизни и потребностям.",
    buyRent: "Аренда, покупка и продажа",
    buyRentText:
      "Аренда, покупка и продажа недвижимости — это три основных способа участия отдельных лиц и семей на рынке недвижимости. Аренда недвижимости обеспечивает большую гибкость и меньшие обязательства, в то время как покупка недвижимости дает возможность владеть и инвестировать в долгосрочный актив. Продажа недвижимости включает в себя маркетинг собственности и переговоры с потенциальными покупателями для обеспечения продажи. Каждый вариант имеет свои преимущества и недостатки, в зависимости от индивидуальных обстоятельств и целей. Независимо от того, хотите ли вы арендовать, купить или продать недвижимость, важно работать с авторитетным агентом по недвижимости, который может помочь вам в этом процессе и помочь вам принять обоснованные решения.",
    listAdd: "Разместите свое объявление",
    listAddText:
      "Размещение объявления о сдаче в аренду или продаже автомобиля на сайте недвижимости — это простой и эффективный способ охватить более широкую аудиторию и увеличить ваши шансы найти подходящего арендатора или покупателя. Чтобы создать привлекательный список, не забудьте включить четкие фотографии собственности или транспортного средства, подробное описание его характеристик и удобств, а также точную информацию о ценах. Выделите любые уникальные преимущества, которые могут выделить вашу собственность или транспортное средство среди конкурентов. Оперативно отвечайте на запросы и будьте готовы показать недвижимость или транспортное средство потенциальным арендаторам или покупателям. С хорошо составленным списком и активным подходом вы можете успешно сдать свою недвижимость в аренду или продать свой автомобиль на сайте недвижимости.",
    getApp: "Получить приложение ЕГАСИ",
    getAppP:
      "С помощью нашего приложения вы сможете покупать и арендовать недвижимость быстрее и эффективнее",
    ourP: "НАШИ ПАРТНЕРЫ",
    searchSomething: "Искать что-нибудь...",
    home: "Дом",
    login: "Авторизоваться",
    logout: "Выйти",
    createAd: "Создать объявление",
    rightPlace: "ПРАВИЛЬНОЕ МЕСТО, ЧТОБЫ НАЙТИ ВАШЕ ИДЕАЛЬНОЕ МЕСТО",
    rightVehicle: "ПРАВИЛЬНОЕ МЕСТО, ЧТОБЫ НАЙТИ ИДЕАЛЬНЫЕ АВТОМОБИЛИ",
    categories: "Категории",
    residentials: "Жилые дома",
    flats: "Квартиры",
    plots: "Сюжеты",
    agriculture: "сельское хозяйство",
    adInfo: "Информация о рекламе",
    reveiews: "Предварительный просмотр",
    pkg: "Упаковка",
    selectPurpose: "Выберите цель",
    selectCat: "Выберите категорию",
    selectBrand: "Выберите Бренд",
    selectModel: "Выбирать Модель",
    select: "Выбирать",
    basicInfo: "Основная информация",
    title: "Заголовок",
    desc: "Описание",
    price: "Цена",
    yes: "Да",
    no: "Нет",
    contactInfo: "Контактная информация",
    namesurename: "Имя и фамилия",
    wtp: "Whatsapp",
    teleg: "Telegram",
    call: "Вызов",
    sms: "SMS",
    locationSettings: "Настройка местоположения",
    country: "Страна",
    city: "Город",
    distt: "Округ",
    internalFeatures: "Внутренние особенности",
    extFeatures: "Внешние особенности",
    safetyFeatures: "Функции безопасности",
    disclose: "я не хочу раскрывать",
    addManually: "Добавить вручную",
    itemName: "Имя элемента",
    bodyParts: "Части тела",
    original: "ОРИГИНАЛ",
    painted: "ОКРАШЕННЫЙ",
    replaced: "ЗАМЕНЕНЫ",
    frontBumper: "Передний бампер",
    engineBonnet: "Капот двигателя",
    ceiling: "Потолок",
    rightFrontFendor: "Правое переднее крыло",

    rightFrontDoor: "Правая передняя дверь",
    rightRearDoor: "Правая задняя дверь",
    leftFrontFendor: "Левое переднее крыло",
    rightRearFendor: "Правое заднее крыло",
    leftRearDoor: "Левая задняя дверь",
    leftRearFendor: "Левое заднее крыло",
    tailgate: "Задние ворота",
    rearBumper: "Задний бампер",
    leftFrontDoor: "Левая передняя дверь",
    continue: "Продолжать",
    loading: "Загрузка",
    neigh: "Район",
    complex: "Сложный",
    openadd: "Открытый адрес",
    accessToTr: "Доступ к транспорту",
    fac: "Удобства для инвалидов",
    location: "расположение",
    purpose: "Цель",
    category: "Категория",
    brand: "Бренд",
    model: "Модель",
    rent: "Арендовать",
    sale: "Распродажа",
    search: "Поиск",
    enterCity: "Введите город...",
    upload: "Загрузить",
    image: "Изображение",
    video: "видео",
    images: "Изображений",
    videos: "Видео",
    add: "Добавлять",
    uploading: "Загрузка...",
    accept: "я принимаю",
    adPosting: "Правила размещения объявлений",
    next: "Следующий",
    subCate: "Подкатегория",
    details: "подробности",
    area: "Область",
    myProfile: "Мой профайл",
    AdManagement: "Управление рекламой",
    balance: "Баланс",
    fav: "Избранное",
    messages: "Сообщения",
    lang: "Язык",
    Priv: "политика конфиденциальности",
    faqs: "Часто задаваемые вопросы",
    reportProb: "Отчет о проблемах",
    about: "О нас",
    norec: "Запись не найдена",
    realEstates: "Недвижимость",
    vehicles: "Транспорт",
    my: "Мой",
    profile: "Профиль",
    personal: "Личный",
    info: "Информация",
    name: "Имя",
    email: "Электронная почта",
    phone: "Телефон",
    taxNbr: "Налоговый номер",
    address: "Адрес",
    update: "Обновлять",
    user: "Пользователь",
    write: "Писать",
    screenshot: "Скриншот",
    fill: "Заполните поле!",
    typeHere: "Введите здесь",
    pleaseSignIn: "Пожалуйста, войдите, чтобы продолжить",
    forgotPassword: "Забыли пароль",
    withNumber: "Войти с номером",
    alreadyHave: "Уже есть учетная запись?",
    signUp: "Зарегистрироваться",
    vehicleFor: "Автомобиль для",
    reportad: "Сообщить об этом объявлении",
    moreDetails: "Подробнее",
    features: "Функции",
    realestateFor: "Недвижимость для",
    delete: "Удалить",
    areYouSure: "Вы уверены, что хотите удалить выбранное объявление?",
    gross: "Валовой",
    net: "Сеть",
    editAd: "Изменить объявление",
    contactUs: "Связаться с нами",
    quickLinks: "Быстрые ссылки",
    followUs: "Подписывайтесь на нас",
    allRights: "Все права защищены",
    createdBy: "Сделано",
    enableDisable: "Включить выключить",
    imageUpload: "Изображение успешно загружено!",
    imageUploadFailed: "Не удалось загрузить изображение!",
    videoUpload: "Видео успешно загружено!",
    videoUploadFailed: "Не удалось загрузить видео!",
    acceptRules: "Пожалуйста, примите Правила размещения объявлений, прежде чем",
    titleErrorMsgOne: "Название должно быть не менее 5 символов",
    titleErrorMsgTwo: "Требуется заголовок",
    descErrorMsgOne: "Описание должно быть не менее 10 символов",
    descErrorMsgTwo: "Требуется описание",
    currencyError: "Требуется валюта",
    priceErrorOne: "Цена должна быть больше 0",
    priceErrorTwo: "Укажите цену",
    required: "Необходимый",
    selectSubCat: "Выберите подкатегорию",
    grossAreaMsgOne: "Общая площадь должна быть больше 0",
    grossAreaMsgTwo: "Требуется общая площадь",
    netAreaMsgOne: "Чистая площадь должна быть больше 0",
    netAreaMsgTwo: "Требуется чистая площадь",
    loginSuccess: "Авторизовался успешно!",
    loginWarn: "Пожалуйста, войдите сначала",
    adCreateMsg: "Добавлен УспехПолностью!",
    adCreateErrorMsg: "Не удалось создать объявление!",
    adUpdateMsg: "Успешно Обновлено!",
    adUpdateErrorMsg: "Не удалось обновить объявление!",
    profileUpdateSuccess: "Профиль успешно обновлен!",
    profileUpdateError: "Не удалось обновить профиль",
    for: "Для",
    bodyPartsErr: "Пожалуйста, выберите все части тела",
    contactError: "Пожалуйста, выберите любую контактную информацию",
    imgErr: "Пожалуйста, выберите хотя бы одно изображение",
    vidErr: "Пожалуйста, выберите видео",
    requiredFields: "Пожалуйста, выберите все обязательные поля",
    submit: "Представлять на рассмотрение",
    reportAdded: "Отчет успешно отправлен!",
    failedToSubmitReport: "Не удалось отправить отчет. Повторите попытку.",
    adName: "Название объявления",
    adPub: "Издатель рекламы",
    reportType: "Тип отчета",
    nudity: "Нагота",
    spam: "Спам",
    unAuthSales: "Несанкционированные продажи",
    violence: "Насилие",
    falseInfo: "Ложная информация",
    somethingElse: "Что-то другое",
    notif: "Уведомления",
    invalidEmail: "Неверный адрес электронной почты",
    addedToFav: "Добавлено в Избранное",
    failedToAdd: "Не удалось, попробуйте еще раз",
    pleaseSelectRecoveryMethod: "Пожалуйста, выберите способ восстановления пароля",
    signIn: "Войти",
    dontHave: "Нет учетной записи?",
    signinWithEmail: "Войти с помощью электронной почты",
    createAcc: "Зарегистрироваться",
    pleaseSelectAccMethod: "Пожалуйста, выберите метод, с помощью которого вы хотите создать учетную запись",
    emailAddress: "Адрес электронной почты",
    mobileNumber: "Номер мобильного телефона",
    pleaseCreateTo: "Пожалуйста, создайте учетную запись, чтобы продолжить",
    pass: "Пароль",
    confirmPass: "Подтвердите пароль",
    readAndAgree: "Я прочитал и согласен с",
    privacyPolicy: "политика конфиденциальности",
    and: "и",
    termsOfUse: "Условия эксплуатации",
    ind: "Индивидуальный",
    corporate: "Корпоративный",
    companyName: "Название компании",
    taxNumber: "Налоговый номер",
    officeNumber: "Номер офиса",
    officeAddress: "Адрес офиса",
    enterTheCode: "Введите код",
    codeThatReceived: "Введите код, который вы получили в смс",
    verify: "Проверять",
    verified: "Успешно проверено!",
    verificationMsg: "Ваш код был успешно проверен. Пожалуйста, продолжайте.",
    dailyRent: "Ежедневная аренда",
    sortBy: "Сортировать по",
    verificatonMsg: "Ваш код был успешно проверен. Пожалуйста, продолжайте.",
    noDuplicate: "Повторная запись не допускается",
    similarAds: "Похожие объявления",
    externalFeatureError: "Пожалуйста, выберите внешнюю функцию или выберите ее, чтобы не раскрывать",
    safetyFeatureError: "Пожалуйста, выберите функцию безопасности или выберите ее, чтобы не раскрывать",
    internalFeatureError: "Пожалуйста, выберите внутреннюю функцию или выберите ее, чтобы не раскрывать",
    pleaseEnterEmail: "Пожалуйста, введите адрес электронной почты, чтобы получить код",
    sendRequestEmail: "Отправить запрос по электронной почте",
    signUpSuccess: "Пользователь успешно зарегистрирован!",
    failedToCreateUser: "Не удалось создать пользователя",
    pleaseEnterNumber: "Пожалуйста, введите свой номер телефона, чтобы получить код",
    checkEmail: "Проверьте свою электронную почту и введите код, чтобы продолжить",
    sendRequest: "Послать запрос",
    resetpassword: "Сброс пароля",
    pleaseEnterNewPassword: "Пожалуйста, введите новый пароль",
    passwordUpdated: "Ваш пароль был обновлен",
    AdNumber: "Номер объявления",
    listingDate: "Дата листинга",
    filters: "Фильтры",
    mapView: "Просмотр карты",
    viewType: "Тип просмотра",
    locationFilter: "Фильтр местоположений",
    searchNearby: "Искать поблизости",
    priceRange: "Ценовой диапазон",
    areaRange: "Область Диапазон",
    bedrooms: "Спальни",
    bathrooms: "Ванные комнаты",
    searchHere: 'Поищи здесь...',
    gender: 'Пол',
    male: 'Мужской',
    female: 'Женский',
    linkCopied: "Ссылка на рекламу скопирована",
    otpSent: 'OTP успешно отправлен!',
    emailIsRequired: "Электронная почта обязательна",
    phoneIsRequired: "Требуется номер телефона",
    verificationCodeRequired: "Требуется код подтверждения"
  },
  uz: {
    all: 'Hammasi',
    accoundDeleteSuccess: 'Hisob muvaffaqiyatli o‘chirildi!',
    accoundDeleteFailed: 'Hisobni o‘chirish muvaffaqiyatsiz tugadi. Iltimos, qayta urinib ko‘ring.',
    deleteAccount: 'Hisobni o‘chirish?',
    areYouSureDeleteAcc: 'Hisobingizni o‘chirishni istaysizmi? Bu barcha eʼlonlaringizni ham o‘chiradi.',

    ThisAdIsCreatedIn: 'Bu reklama yilida yaratilgan',
    PleaseChangeYourLanguageTo: `Iltimos, tilingizni oʻzgartiring`,
    thenYouCanEdit: 'keyin tahrirlashingiz mumkin',
    yourCurrentLocation: 'Joriy joylashuvingiz',
    map: 'Xarita',
    car: 'Avtomobil',
    standardPackage: 'Standart paket',
    remainingAds: 'Qolgan reklamalar',
    numberOfAds: `E'lonlar soni`,
    pleaseSelectPlan: 'Iltimos, Rejani tanlang',
    totalAdsRemaining: 'Qolgan jami reklamalar',
    freeAdsRemaining: 'Qolgan bepul reklamalar',
    boughtPackages: 'Paketlar sotib olindi',
    noPackageFound: 'Paket topilmadi',
    otherPackages: 'Boshqa paketlar',
    resendCode: 'Kodni qayta yuborish',
    pleaseClickOnSection: `Iltimos, reklama yaratmoqchi bo'lgan bo'limni bosing'`,
    proceedWithFreePlan: 'Bepul reja bilan davom eting',
    packages: 'Paketlar',
    locationAcces: `Iltimos, joylashuv maʼlumotlarini olish uchun joylashuvga ruxsat bering`,
    status: "Holat",
    active: "Faol",
    inActive: "Faolda",
    featured: "Tanlangan",
    expired: "Muddati tugagan",
    pending: "Kutilmoqda",
    rejected: "Rad etilgan",
    coverPhoto: 'Muqova fotosurati',
    disabledFacilities: `Nogironlar ob'ektlari`,
    comingSoon: 'Tez kunlarda!',
    emailVerify: 'Elektron pochtani tekshirish',
    userVerified: 'Foydalanuvchi muvaffaqiyatli tasdiqlandi',
    priceHighest: 'Narxi eng yuqori birinchi',
    priceLowest: 'Narxi eng past birinchi',
    newest: 'Eng yangi birinchi',
    oldFirst: 'Eski birinchi',
    alpAZ: 'Alifbo (A-Z)',
    alpZA: 'Alifbo (Z-A)',
    noCategory: 'Kategoriyasiz',
    type: 'Turi',
    house: 'Uy',
    recommendedRealEstates: "Tavsiya etilgan ko'chmas mulk",
    recommendedVehicles: "Tavsiya etilgan transport vositalari",
    topCities: "Eng yaxshi shaharlar",
    whatDo: "Nima qila olasiz?",
    findPlace: "O'zingizning orzuingizdagi joyni toping",
    findPlaceText:
      "Mulk uchun orzuingizdagi joyni topish juda qiyin vazifa bo'lishi mumkin, ammo to'g'ri vositalar va fikrlash bilan bu hayajonli va foydali tajriba bo'lishi mumkin. O'zingizning ustuvorliklaringizni belgilashdan boshlang, bu qulayliklarga yaqinlik, uyning ma'lum bir uslubi yoki ma'lum bir joy. Mulklarni ko'rib chiqish va mahallalar va jamoalar haqida ma'lumot to'plash uchun onlayn resurslardan foydalaning. Jarayon davomida yo'l-yo'riq va yordam beradigan ishonchli ko'chmas mulk agenti bilan ishlahni o'ylab ko'ring. Sabr-toqat va qat'iyat bilan siz uyga qo'ng'iroq qilish uchun eng zo'r joyni topishingiz mumkin.",
    findVehicle: "O'zingizning orzuingizdagi avtomobilni toping",
    findVehicleText:
      "O'zingizning orzuingizdagi avtomobilni topish hayajonli tajriba bo'lishi mumkin, ammo mavjud variantlarning keng doirasi bilan ham hayratlanarli bo'lishi mumkin. Avtomobil turi, yoqilg'i samaradorligi va siz uchun muhim bo'lgan xususiyatlar kabi ehtiyojlaringiz va afzalliklaringizni aniqlashdan boshlang. Mavjud turli markalar va modellarni o'rganing va mutaxassislar va egalarining sharhlarini o'qing. Avtomobillarni his qilish uchun sinov drayverlarini o'tkazing va ular sizning taxminlaringizga mos keladimi yoki yo'qligini tekshiring. Sizga nima mos kelishini aniqlash uchun byudjet va moliyalashtirish imkoniyatlarini ko'rib chiqing. Ehtiyotkorlik bilan ko'rib chiqish va izlanish bilan siz hayot tarzingiz va ehtiyojlaringizga mos keladigan mukammal transport vositasini topishingiz mumkin",
    buyRent: "Ijaraga olish, sotib olish va sotish",
    buyRentText:
      "Ko'chmas mulkni ijaraga olish, sotib olish va sotish jismoniy shaxslar va oilalarning ko'chmas mulk bozorida ishtirok etishining uchta asosiy usuli hisoblanadi. Mulkni ijaraga olish ko'proq moslashuvchanlik va kamroq majburiyatlarni olish imkonini beradi, mulkni sotib olish esa uzoq muddatli aktivga egalik qilish va investitsiya qilish imkoniyatini beradi. Mulkni sotish mulkni sotish va sotishni ta'minlash uchun potentsial xaridorlar bilan muzokaralar olib borishni o'z ichiga oladi. Har bir variant individual sharoit va maqsadlarga qarab o'zining afzalliklari va kamchiliklariga ega. Ko‘chmas mulkni ijaraga olmoqchi bo‘lsangiz, sotib olmoqchi bo‘lsangiz yoki sotmoqchi bo‘lsangiz, bu jarayonda sizga yo‘l-yo‘riq ko‘rsatadigan va ongli qarorlar qabul qilishingizga yordam beradigan nufuzli ko‘chmas mulk agenti bilan ishlash muhimdir.",
    listAdd: "E'loningizni ro'yxatlang",
    listAddText:
      "Ko'chmas mulk saytida ijaraga beriladigan mulkingiz yoki sotiladigan avtomobilingiz ro'yxati kengroq auditoriyani qamrab olish va mos ijarachi yoki xaridorni topish imkoniyatingizni oshirishning oddiy va samarali usuli hisoblanadi. Jozibali ro'yxatni yaratish uchun mulk yoki transport vositasining aniq fotosuratlarini, uning xususiyatlari va qulayliklarining batafsil tavsifini va aniq narx ma'lumotlarini kiritganingizga ishonch hosil qiling. Sizning mulkingiz yoki avtomobilingizni raqobatchilardan ajratib turadigan har qanday noyob savdo nuqtalarini ajratib ko'rsating. So'rovlarga zudlik bilan javob bering va potentsial ijarachilar yoki xaridorlarga mulk yoki transport vositasini ko'rsatishga tayyor bo'ling. Yaxshi ishlab chiqilgan ro'yxat va proaktiv yondashuv bilan siz o'z mulkingizni muvaffaqiyatli ijaraga berishingiz yoki avtomobilingizni mulk saytida sotishingiz mumkin.",
    getApp: "EGASI ilovasini yuklab oling",
    getAppP:
      "Bizning ilovamizdan foydalanib, siz mulkni tezroq va samaraliroq sotib olishingiz va ijaraga olishingiz mumkin.",
    ourP: "BIZNING HAMKORLIK",
    searchSomething: "Nimadir qidirish",
    home: "Uy",
    login: "Tizimga kirish",
    logout: "Chiqish",
    createAd: "Reklamani yaratish",
    rightPlace: "O'ZINGIZNI MUMKIN JOYINGIZNI TOPISH UCHUN TO'G'RI JOY",
    rightVehicle: "MUMKIN MOSHINALARNI TOPISH UCHUN TO'G'RI JOY",
    categories: "Kategoriyalar",
    residentials: "Turar joy",
    flats: "Kvartiralar",
    plots: "Syujetlar",
    agriculture: "Qishloq xo'jaligi",
    adInfo: "Reklama haqida ma'lumot",
    reveiews: "Ko'rib chiqish",
    pkg: "Paket",
    selectPurpose: "Tanlang Maqsad",
    selectCat: "Tanlang Turkum",
    selectBrand: "Tanlang Brend",
    selectModel: "Tanlang Model",
    select: "Tanlang",
    basicInfo: "Asosiy ma'lumotlar",
    title: "Sarlavha",
    desc: "Tavsif",
    price: "Narxi",
    yes: "Ha",
    no: "Yo'q",
    contactInfo: "Bog'lanish uchun ma'lumot",
    namesurename: "ismi va familiyasi",
    wtp: "Whatsapp",
    teleg: "Telegram",
    call: "Qo'ng'iroq qiling",
    sms: "SMS",
    locationSettings: "Joylashuv sozlamalari",
    country: "Mamlakat",
    city: "Shahar",
    distt: "Tuman",
    internalFeatures: "Ichki xususiyatlar",
    extFeatures: "Tashqi xususiyatlar",
    safetyFeatures: "Xavfsizlik xususiyatlari",
    disclose: "Men oshkor qilmoqchi emasman",
    addManually: "Qo'lda qo'shish",
    itemName: "Element nomi",
    bodyParts: "Tana qismlari",
    original: "ASL",
    painted: "BO'YILGAN",
    replaced: "ALSHIB OLGAN",
    frontBumper: "Old bamper",
    engineBonnet: "Dvigatel qopqog'i",
    ceiling: "Shift",
    rightFrontFendor: "O'ng old Fendor",
    rightFrontDoor: "O'ng old eshik",
    rightRearDoor: "O'ng orqa eshik",
    leftFrontFendor: "Chap old Fendor",
    rightRearFendor: "O'ng orqa Fendor",
    leftRearDoor: "Chap orqa eshik",
    leftRearFendor: "Chap orqa Fendor",
    tailgate: "Orqa eshik",
    rearBumper: "Orqa bamper",
    leftFrontDoor: "Chap old eshik",
    continue: "Davom eting",
    loading: "Yuklanmoqda",
    neigh: "Turar joy dahasi",
    complex: "Kompleks",
    openadd: "Manzilni oching",
    accessToTr: "Transportga kirish",
    fac: "O'chirish uchun imkoniyatlar",
    location: "Manzil",
    purpose: "maqsad",
    category: "Turkum",
    brand: "Brend",
    model: "Model",
    rent: "Ijara",
    sale: "Sotish",
    search: "Qidirmoq",
    enterCity: "Shaharga kiring...",
    upload: "Yuklash",
    image: "Rasm",
    video: "Video",
    images: "Tasvirlar",
    videos: "Videolar",
    add: "Qo'shish",
    uploading: "Yuklanmoqda...",
    accept: "qabul qilaman",
    adPosting: "Reklamani joylashtirish qoidalari",
    next: "Keyingisi",
    subCate: "Pastki toifa",
    details: "tafsilotlar",
    area: "Hudud",
    myProfile: "Biz haqimizda",
    AdManagement: "Reklamani boshqarish",
    balance: "Balans",
    fav: "Sevimlilar",
    messages: "Xabarlar",
    lang: "Til",
    Priv: "Maxfiylik siyosati",
    faqs: "Tez-tez so'raladigan savollar",
    reportProb: "Muammo haqida xabar berish",
    about: "Biz haqimizda",
    norec: "Hech qanday yozuv topilmadi",
    realEstates: "Ko'chmas mulk",
    vehicles: "Avtomobillar",
    my: "Yangilash",
    profile: "Profil",
    personal: "Shaxsiy",
    info: "Ma `lumot",
    name: "Ism",
    email: "Elektron pochta",
    phone: "Telefon",
    taxNbr: "Soliq raqami",
    address: "Manzil",
    update: "Yangilash",
    user: "Foydalanuvchi",
    write: "Yozing",
    screenshot: "Skrinshot",
    fill: "Maydonni to'ldiring!",
    typeHere: "Bu yerga yozing",
    pleaseSignIn: "Davom etish uchun tizimga kiring",
    forgotPassword: "Parolni unutdingizmi",
    withNumber: "Raqam bilan tizimga kiring",
    alreadyHave: "Hisobingiz bormi?",
    signUp: "Ro'yxatdan o'tish",
    vehicleFor: "uchun avtomobil",
    reportad: "Ushbu reklama haqida xabar bering",
    moreDetails: "Batafsil",
    features: "Xususiyatlari",
    realestateFor: "uchun ko'chmas mulk",
    delete: "Oʻchirish",
    areYouSure: "Haqiqatan ham, tanlangan reklamani oʻchirib tashlamoqchimisiz?",
    gross: "Yalpi",
    net: "Net",
    editAd: "Reklamani tahrirlash",
    contactUs: "Biz bilan bog'lanish",
    quickLinks: "Tez havolalar",
    followUs: "Ortimizdan yuring",
    allRights: "Barcha huquqlar himoyalangan",
    createdBy: "Tomonidan yaratilgan",
    enableDisable: "Yoqish/o‘chirish",
    imageUpload: "Rasm muvaffaqiyatli yuklandi!",
    imageUploadFailed: "Rasm yuklanmadi!",
    videoUpload: "Video muvaffaqiyatli yuklandi!",
    videoUploadFailed: "Video yuklanmadi!",
    acceptRules: "Iltimos, oldin reklama joylashtirish qoidalarini qabul qiling",
    titleErrorMsgOne: "Sarlavha kamida 5 ta belgidan iborat boʻlishi kerak",
    titleErrorMsgTwo: "Sarlavha kerak",
    descErrorMsgOne: "Tavsif kamida 10 ta belgidan iborat boʻlishi kerak",
    descErrorMsgTwo: "Tavsif talab qilinadi",
    currencyError: "Valyuta talab qilinadi",
    priceErrorOne: "Narx 0 dan katta bo'lishi kerak",
    priceErrorTwo: "Narx talab qilinadi",
    required: "Majburiy",
    selectSubCat: "Pastki toifani tanlang",
    grossAreaMsgOne: "Yalpi maydon 0 dan katta bo'lishi kerak",
    grossAreaMsgTwo: "Yalpi maydon talab qilinadi",
    netAreaMsgOne: "Net maydon 0 dan katta bo'lishi kerak",
    netAreaMsgTwo: "Net maydon talab qilinadi",
    loginSuccess: "Muvaffaqiyatli tizimga kirdingiz!",
    loginWarn: "Iltimos, avval tizimga kiring",
    adCreateMsg: "Muvaffaqiyatli qo'shildi!",
    adCreateErrorMsg: "Reklamani yaratib bo'lmadi!",
    adUpdateMsg: "Muvaffaqiyatli yangilandi!",
    adUpdateErrorMsg: "Reklamani yangilab boʻlmadi!",
    profileUpdateSuccess: "Profil muvaffaqiyatli yangilandi!",
    profileUpdateError: "Profilni yangilab bo‘lmadi",
    for: "Uchun",
    bodyPartsErr: "Iltimos, tananing barcha qismlarini tanlang",
    contactError: "Iltimos, kontakt ma'lumotlaridan birini tanlang",
    imgErr: "Iltimos, kamida bitta rasmni tanlang",
    vidErr: "Videoni tanlang",
    requiredFields: "Iltimos, barcha kerakli maydonlarni tanlang",
    submit: "Yuborish",
    reportAdded: "Hisobot muvaffaqiyatli topshirildi!",
    failedToSubmitReport: "Hisobotni topshirib bo‘lmadi, Qaytadan urining",
    adName: "Reklama nashriyotchisi",
    adPub: "Reklama nashriyotchis",
    reportType: "Hisobot turi",
    nudity: "Yalang'ochlik",
    spam: "Spam",
    unAuthSales: "Ruxsatsiz sotish",
    violence: "Zo'ravonlik",
    falseInfo: "Yolg'on ma'lumot",
    somethingElse: "Boshqa bir narsa",
    notif: "Bildirishnomalar",
    invalidEmail: "Email manzili noto‘g‘ri",
    addedToFav: "Sevimlilarga qo'shildi",
    failedToAdd: "Muvaffaqiyatsiz, qaytadan urining",
    pleaseSelectRecoveryMethod: "Parolni tiklash usulini tanlang",

    signIn: "Tizimga kirish",
    dontHave: "Hisobingiz yo'qmi?",
    signinWithEmail: "Elektron pochta orqali tizimga kiring",
    createAcc: "Hisob yaratish",
    pleaseSelectAccMethod: "Iltimos, hisob yaratmoqchi bo'lgan usulni tanlang",
    emailAddress: "E-pochta manzili",
    mobileNumber: "Mobil telefon raqami",
    pleaseCreateTo: "Davom etish uchun hisob yarating",
    pass: "Parol",
    confirmPass: "Parolni tasdiqlang",
    readAndAgree: "Men o'qidim va roziman",
    privacyPolicy: "Maxfiylik siyosati",
    and: "va",
    termsOfUse: "Foydalanish shartlari",
    ind: "Individual",
    corporate: "Korporativ",
    companyName: "Shirkat nomi",
    taxNumber: "Soliq raqami",
    officeNumber: "Ofis raqami",
    officeAddress: "Ofis manzili",
    enterTheCode: "Kodni kiriting",
    codeThatReceived: "SMS orqali olingan kodni kiriting",
    verify: "Tasdiqlash",
    verified: "Tasdiqlandi!",
    verificationMsg: "Kodingiz muvaffaqiyatli tekshirildi. Davom eting.",
    dailyRent: "Kunlik ijara",
    sortBy: "Saralash turi",
    verificatonMsg: "Kodingiz muvaffaqiyatli tekshirildi. Davom eting.",
    noDuplicate: "Ikki nusxadagi kirishga ruxsat berilmaydi",
    similarAds: "Shu kabi reklamalar",
    externalFeatureError: "Iltimos, tashqi xususiyatni tanlang yoki oshkor qilmaslik uchun uni tanlang",
    safetyFeatureError: "Iltimos, Xavfsizlik funksiyasini tanlang yoki oshkor qilmaslik uchun uni tanlang",
    internalFeatureError: "Iltimos, ichki xususiyatni tanlang yoki oshkor qilmaslik uchun uni tanlang",
    pleaseEnterEmail: "Iltimos, kodni olish uchun elektron pochta manzilini kiriting",
    sendRequestEmail: "So'rovni elektron pochta orqali yuborish",
    signUpSuccess: "Foydalanuvchi muvaffaqiyatli ro'yxatdan o'tdi!",
    failedToCreateUser: "Foydalanuvchi yaratib bo‘lmadi",
    pleaseEnterNumber: "Iltimos, kodni olish uchun telefon raqamingizni kiriting",
    checkEmail: "Davom etish uchun elektron pochtangizni tekshiring va kodni kiriting",
    sendRequest: "So'rov yuboring",
    resetpassword: "Parolni tiklash",
    pleaseEnterNewPassword: "Iltimos, yangi parolingizni kiriting",
    passwordUpdated: "Parolingiz yangilandi",
    addLogo: "Logotip qo'shing",
    AdNumber: "E'lon raqami",
    listingDate: "Ro'yxat sanasi",
    filters: "Filtrlar",
    mapView: "Xarita ko'rinishi",
    viewType: "Ko'rish turi",
    locationFilter: "Joylashuv filtri",
    searchNearby: "Yaqin-atrofda qidirish",
    priceRange: "Narx diapazoni",
    areaRange: "Hudud diapazoni",
    bedrooms: "Yotoq xonalari",
    bathrooms: "Hammomlar",
    searchHere: 'Bu yerda qidiring...',
    gender: 'Jins',
    male: 'Erkak',
    female: 'Ayol',
    linkCopied: "Reklama havolasi nusxalandi",
    otpSent: 'OTP muvaffaqiyatli yuborildi!',
    emailIsRequired: "Elektron pochta kerak",
    phoneIsRequired: "Telefon raqami kerak",
    verificationCodeRequired: "Tasdiqlash kodi kerak"
  },
});

export default strings;
