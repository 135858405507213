import React, { useState } from 'react';
import apiClient from '../../utils/apiClient';
import { ClipLoader } from 'react-spinners';
import { toast } from "react-toastify";
import { generateOrderNumber } from '../../utils/generateOrderNumber';
import strings from '../../utils/locals/languages';


const PackageCard = ({ package: pkg, buy, onClick }) => {

    const [loading, setLoading] = useState(false)

    const handlePayment = async (pkg) => {
        sessionStorage.setItem('selectedPackage', JSON.stringify(pkg));

        const SERVICE_ID = process.env.REACT_APP_PAYMENT_SERVICE_ID
        const MERCHANT_ID = process.env.REACT_APP_PAYMENT_MERCHANT_ID
        const AMOUNT = pkg.price
        const TRANSACTION_PARAM = await generateOrderNumber()
        const paymentUrl = `https://my.click.uz/services/pay/?service_id=${SERVICE_ID}&merchant_id=${MERCHANT_ID}&amount=${AMOUNT}&transaction_param=${TRANSACTION_PARAM}&
            &return_url=https://www.egasi.net/packages&card_type=humo`;
        window.location.href = paymentUrl;
    };

    const handleReturn = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const status = queryParams.get('payment_status');

        if (status === 2) {
            try {
                const storedPackage = JSON.parse(sessionStorage.getItem('selectedPackage'))

                await buyPackage(storedPackage)

                sessionStorage.removeItem('selectedPackage');

            } catch (error) {
                toast.error("Payment was unsuccessfull")
            }
        } else {
            toast.error("Payment was unsuccessfull")
        }
    };

    window.onload = handleReturn;


    const buyPackage = async (data) => {
        setLoading(true)
        try {
            const response = await apiClient
                .post(`/v1/user/buy-package`, {
                    packageId: data._id,
                    title: data.title,
                    price: data.price,
                    text: data.text,
                    numberOfAds: data.numberOfAds,
                    currency: data.currency
                })

            if (response.data.success === true) {
                toast.success(response.data.message)
            } else {
                toast.error(response.data.message)
            }
            setLoading(false)


        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    return (
        <div className={`bg-gray-100 shadow-lg h-[300px] w-80 rounded-md flex flex-col items-center `}

        >
            <div className="bg-[rgb(243,117,33)] rounded-t-lg text-white w-full text-center py-2 text-lg font-bold">{pkg.title || 'New Plan'}</div>
            <div className="flex-1 my-4 w-[90%] h-[270px] text-base">{pkg.text.slice(0, 80)}</div>
            <div className="text-xl flex w-[90%] justify-between mb-2">
                <div>{buy ? strings.numberOfAds : strings.remainingAds}</div>
                <div>{pkg.numberOfAds}</div>
            </div>
            <div className="text-xl flex w-[90%] justify-between mb-2">
                <div>{strings.price}</div>
                <div>{pkg.currency}{pkg.price}</div>
            </div>

            {
                buy && <button className="bg-[#F37521] text-white py-2 px-4 rounded-full mb-4 mt-4 hover:bg-[#D3621E]"

                    onClick={() => {
                        if (onClick) {
                            handlePayment(pkg)
                        }
                    }}
                >
                    {loading ? <ClipLoader size={20} color='#fff' /> : 'Buy'}
                </button>
            }

        </div>
    );
};

export default PackageCard;
