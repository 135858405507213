import React from 'react'
import Preview from './Preview'
import AppBar from "../../components/AppBar";
import { useLocation } from 'react-router-dom';

const RealEstatePreview = () => {

  const location = useLocation()
  const { isEdit } = location.state

  return (
    <>
      <AppBar id='' createPage='' />
      <Preview isEdit={isEdit} />
    </>
  )
}

export default RealEstatePreview