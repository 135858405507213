import React, { useState, useEffect, useRef } from "react";

import { setLanguage } from "../../app/languageSlice";
import { useDispatch } from "react-redux";

import strings from "../../utils/locals/languages";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom"

import { useFormik } from "formik";
import Footer from "../../components/Footer";
import apiClient from "../../utils/apiClient";
import { toast } from "react-toastify";

const ReportProblem = () => {
    const location = useLocation()
    const reportDetails = location.state.innerdetails
    const [profileData, setProfileData] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    const [imageLoading, setImageLoading] = useState(false)
    const [toggle, setToggle] = useState(false);
    const navigate = useNavigate()
    const [menu1Open, setMenu1Open] = useState(false);
    const button1Ref = useRef(null);
    const button2Ref = useRef(null);
    const hiddenFileInput = useRef(null);

    const handleInputClick = (event) => {
        hiddenFileInput.current.click();
    };

    const uploadImage = (File) => {
        setImageLoading(true);
        const formData = new FormData();
        formData.append("files", File);

        apiClient
            .post(
                `/v1/adds/upload-multiple`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            .then((res) => {
                setImageLoading(false);
                formik.setFieldValue("imageUrl", res.data[0])
                toast.success(`${strings.imageUpload}`)
            })
            .catch((err) => {
                toast.error(`${strings.imageUploadFailed}`)

            });
    };


    // event handlers for opening and closing each menu
    const handleMenu1Open = (event) => {
        setMenu1Open(true);
    };
    const handleMenu1Close = () => {
        setMenu1Open(false);
    };
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleLanguageChange = (language) => {
        strings.setLanguage(language); // set the language for the LocalizedStrings instance
        dispatch(setLanguage(language)); // dispatch an action to update the language in the store
    };

    const submitData = (data) => {
        setIsLoading(true)
        apiClient.post("/v1/adds/report", data)
            .then((res) => {
                setIsLoading(false)
                toast.success(`${strings.reportAdded}`)
                navigate('/')
            })
            .catch((err) => {
                setIsLoading(false)
                toast.error(`${strings.failedToSubmitReport}`)
            })
    }

    const formik = useFormik({
        initialValues: {},
        onSubmit: () => {
            submitData(formik.values)
        }
    })
    useEffect(() => {
        
        const { title, name, email, ...rest } = reportDetails;
        const updatedInnerdetails = { ...rest };
        formik.setValues(updatedInnerdetails)
    }, [])

    useEffect(() => {
        const userDetails = Cookies.get("userDetails")
        const user = JSON.parse(userDetails);
        const {
            name,
            companyName,
            phone,
            email,
            country,
            city,
            logo,
            address,
            image,
            taxNumber,
            officePhone,
        } = user;
        const filteredUser = {
            name,
            companyName,
            phone,
            email,
            country,
            city,
            logo,
            address,
            image,
            taxNumber,
            officePhone,
        };

        setProfileData(filteredUser);
    }, [])



   

    return (
        <>
          
            <div className="container mx-auto">
                <h6 className="text-center">{strings.reportProb}</h6>
            </div>
            <div className="container mx-auto">
                <h6>{strings.user} {strings.info}</h6>
            </div>

            <div className="container mx-auto">
                <div className="w-64 lg:w-96 md:w-96 flex justify-between mt-3">
                    <p>{strings.name}</p>
                    <spanr>{profileData.name}</spanr>
                </div>

                <div className="w-64 lg:w-96 md:w-96 flex justify-between mt-1">
                    <p>{strings.email}</p>
                    <spanr>{profileData.email}</spanr>
                </div>

                <div className="w-64 lg:w-96 md:w-96 flex justify-between mt-1">
                    <p>{strings.phone}</p>
                    <spanr>{profileData?.phone}</spanr>
                </div>

            </div>

            <div className="container mx-auto">
                <h6>{strings.adInfo}</h6>
            </div>

            <div className="container mx-auto">
                <div className="w-64 lg:w-96 md:w-96 flex justify-between mt-3">
                    <p>{strings.adName}</p>
                    <spanr>{reportDetails.title}</spanr>
                </div>

                <div className="w-64 lg:w-96 md:w-96 flex justify-between mt-1">
                    <p>{strings.adPub}</p>
                    <spanr>{reportDetails.name}</spanr>
                </div>

            </div>

            <div className="container mx-auto mt-4">
                <h6>{strings.write} {strings.details}</h6>

                <div>

                    <p className="mt-5">{strings.reportType}</p>
                    <select
                        className="custom-select w-72 h-14 rounded-full  border-2 shadow-md  bg-white  p-1"
                        placeholder={strings.reportType}
                        onChange={e => formik.setFieldValue("reportType", e.target.value)}
                        value={formik.values.reportType}
                    >
                        <option>{strings.select}</option>
                        <option>{strings.nudity}</option>
                        <option>{strings.spam}</option>
                        <option>{strings.unAuthSales}</option>
                        <option>{strings.violence}</option>
                        <option>{strings.falseInfo}</option>
                        <option>{strings.somethingElse}</option>
                    </select>
                </div>

                <div>
                    <p className="mt-5">{strings.desc}</p>

                    <textarea className="bg-white p-3 rounded-lg h-32 shadow-md w-64 lg:w-96 md:w-96 "
                        onChange={(e) => {
                            formik.setFieldValue("detail", e.target.value)
                        }}
                        value={formik.values.detail}
                    >
                        {strings.desc}
                    </textarea>
                </div>
            </div>

            <div className="container mx-auto mt-4">
                <h6>{strings.add} {strings.screenshot}</h6>

                <div className="bg-white p-3 grid grid:cols-1 justify-items-center rounded-lg h-32 mt-4 shadow-md w-64 lg:w-96 md:w-96 ">
                    <input
                        type="file"
                        style={{ display: "none" }}
                        ref={hiddenFileInput}
                        onChange={(e) => {
                            uploadImage(e.target.files[0]);
                        }}
                    />
                    <div className="flex justify-center">
                        <button className="bg-orange-400 text-white w-32 h-12 rounded-xl mt-1"
                            onClick={handleInputClick}
                        >
                            {
                                imageLoading ? strings.loading : strings.upload
                            }
                        </button>
                    </div>
                </div>
            </div>

            <div className="container mx-auto mt-5">
                <div className="flex justify-center">
                    <button className="bg-orange-400 text-white w-64 lg:w-80 md:w-72 p-3 rounded-full"
                        onClick={formik.handleSubmit}
                    >
                        {
                            isLoading ? strings.loading : strings.submit
                        }
                    </button>
                </div>
            </div>

            {/*footer*/}

            <Footer strings={strings} />
        </>
    );
};

export default ReportProblem;
