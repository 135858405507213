import React from 'react'
import EditState from './EditState'
import AppBar from "../../components/AppBar";

const EditRealEstate = () => {
  return (
    <>
      <AppBar id='' createPage='' />
      <EditState />
    </>
  )
}

export default EditRealEstate