import React, { useEffect, useState } from 'react'
import Modal from "react-bootstrap/Modal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import strings from '../../utils/locals/languages';
import { baseURL1 } from '../../utils/baseUrl';
import axios from 'axios';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const AdCreateModal = ({ showCreateAdModal, setShowCreateAdModal }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([]);
    const navigate = useNavigate()

    const handleClose = () => {
        setShowCreateAdModal(false)
    }

    const fetchData = async () => {
        try {
            const response = await axios.get(`${baseURL1}/v1/adds/sections?language=${strings._language}`);

            // Simulate a delay before setting the data
            setData(response.data);
            setIsLoading(false);
        } catch (error) {
            setData([]);
        }
    };

    useEffect(() => {

        fetchData()
    }, [])
    return (
        <Modal
            size="xl"
            show={showCreateAdModal}
            onHide={handleClose}
            centered
        >


            <div className="p-10  mb-3">
            {
                    !isLoading && (
                        <div className='w-full text-center -mt-5 text-xl'>
                            {strings.pleaseClickOnSection}
                        </div>
                    )
                }
                {isLoading ? (


                    <div
                        className="grid grid-cols-1 md:flex md:justify-between md:items-center "

                    >
                        {[1, 2, 3, 4].map((index) => (
                            <div key={index}
                                className="cursor-pointer md:h-[180px] lg:h-[180px] md:mb-0 "
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Skeleton
                                    style={{
                                        height: '180px',
                                        width: '250px',
                                        md: { height: '180px' },
                                        lg: { height: '180px' },
                                        padding: '10px',
                                        borderRadius: '10px'
                                    }}

                                />
                                <Skeleton
                                    // height={40}
                                    width={120}
                                    style={{ marginTop: "0.5rem" }}
                                />
                            </div>
                        ))}
                    </div>

                ) : (
                    <div
                        className="grid grid-cols-1 md:flex md:justify-between md:items-center text-center "

                    >

                        {data
                            .filter(
                                (item) =>
                                    item.title === "Services" ||
                                    item.title === "транспортное средство" ||
                                    item.title === "transport vositasi" ||
                                    item.title === "недвижимость" ||
                                    item.title === "Ko'chmas mulk" ||
                                    item.title === "Jobs" ||
                                    item.title === "Работа" ||
                                    item.title === "Ishlar" ||
                                    item.title === "Услуги" ||
                                    item.title === "Xizmatlar" ||
                                    item.title === "Real Estate" ||
                                    item.title === "Vehicle" ||
                                    item.title === "Spare parts" ||
                                    item.title === "Запчасти" ||
                                    item.title === "Ehtiyot qismlar"
                            )
                            .map((item, index) => (
                                <div
                                    key={item._id}
                                    className="cursor-pointer px-4 mt-2 mr-2 rounded-2xl bg-white shadow-md  "
                                    style={{
                                        // height: 300,
                                        // width: 200,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                    onClick={() => {
                                        const validTitles = [
                                            "Vehicle",
                                            "транспортное средство",
                                            "transport vositasi",
                                            "Real Estate",
                                            "Ko'chmas mulk",
                                            "недвижимость",
                                        ];

                                        if (validTitles.includes(item.title)) {
                                            navigate(
                                                `${(item.title === "Vehicle" ||
                                                    item.title === "транспортное средство" ||
                                                    item.title === "transport vositasi")
                                                    ? "/create-vehicle-ad"
                                                    : (item.title === "Real Estate" ||
                                                        item.title === "Ko'chmas mulk" ||
                                                        item.title === "недвижимость")
                                                        ? "/create-realestate-ad"
                                                        : ""
                                                }`,
                                                {
                                                    state: {
                                                        id: item._id,
                                                    },
                                                }
                                            );
                                        } else {
                                            toast.info(`${strings.comingSoon}`);
                                        }
                                    }}
                                >
                                    <img
                                        className=" object-cover  md:h-[180px] lg:h-[180px] w-[250px]  bg-white"
                                        src={item.imageUrl}
                                        alt=""
                                    />
                                    <h1 className="font-semibold text-sm md:text-lg lg:text-md pe-2  mt-3  text-gray-700">
                                        {item.title
                                            .split(" ")
                                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                            .join(" ")}
                                    </h1>
                                </div>
                            ))}
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default AdCreateModal