import React from 'react'
import strings from '../../utils/locals/languages';
import { toast } from 'react-toastify';
import { handleRealEstateClick } from '../../services/handlers';

const RealEstateAds = ({
    data,
    user,
    sortBy,
    navigate,
    Dropdown,
    dot,
    Cookies,
    setShowModal,
    setDeletingItemId,
    beds,
    area,
    wash,
    status
}) => {

 

    return (
        <div className="conta mx-auto mt-4">
            <div className="container ">
                <h2 className="uppercase font-semibold text-base">
                    {strings.realEstates}{" "}
                </h2>
                {data?.filter((object) => object.user?._id === user?._id)
                    .length === 0 ? (
                    <div className="flex justify-center">
                        <h3>{strings.norec}</h3>
                    </div>
                ) : (
                    <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-5 justify-center">
                        {data
                            ?.filter((object) => ((object?.user?._id === user?._id) && (object.status === status)))
                            .sort((a, b) => {
                                if (sortBy === "ph") {
                                    const priceA = Number(a.basicInformation.price.price.replace(/,/g, ''));
                                    const priceB = Number(b.basicInformation.price.price.replace(/,/g, ''));

                                    return priceB - priceA;
                                } else if (sortBy === "pl") {
                                    const priceA = Number(a.basicInformation.price.price.replace(/,/g, ''));
                                    const priceB = Number(b.basicInformation.price.price.replace(/,/g, ''));

                                    return priceA - priceB;
                                } else if (sortBy === "atoz") {
                                    return a.basicInformation.title.localeCompare(
                                        b.basicInformation.title
                                    ); // Sort A to Z
                                } else if (sortBy === "ztoa") {
                                    return b.basicInformation.title.localeCompare(
                                        a.basicInformation.title
                                    ); // Sort Z to A
                                } else if (sortBy === "new") {
                                    return (
                                        new Date(b.createdAt) - new Date(a.createdAt)
                                    ); // Sort by newest createdAt
                                } else if (sortBy === "old") {
                                    return (
                                        new Date(a.createdAt) - new Date(b.createdAt)
                                    ); // Sort by oldest createdAt
                                } else {
                                    // Handle other sorting options if needed
                                    return 0; // No sorting
                                }
                            })
                            .map((item, index) => {
                                let isDropdownToggleClicked = false;
                                return (
                                    <div
                                        className="ca rounded-xl shadow-md border-none cursor-pointer overflow-hidden -mb-5 w "
                                        style={
                                            {
                                                // borderTopRightRadius: 12,
                                                // borderTopLeftRadius: 12,
                                                // boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`,
                                            }
                                        }
                                        onClick={() => {
                                            if (!isDropdownToggleClicked) {
                                                handleRealEstateClick(navigate, item._id, item.basicInformation.title, item.basicInformation.category.title, item.basicInformation.brand.title, item.location.city, item.location.district)

                                            }
                                        }}
                                    >
                                        <div
                                            className="relative flex h-40"
                                        // style={{ height: "200px" }}
                                        >
                                            <img
                                                className="mb- r"
                                                // src={item.imageUrl}
                                                src={item.coverPhoto}
                                                alt="random"
                                                style={{
                                                    height: "100%",
                                                    width: "100%",
                                                    objectFit: "cover",
                                                    // borderTopRightRadius: 12,
                                                    // borderTopLeftRadius: 12,
                                                }}
                                            />
                                            <Dropdown className="bg-none">
                                                <Dropdown.Toggle
                                                    style={{
                                                        position: "absolute",
                                                        top: "5px",
                                                        right: "0",
                                                        zIndex: "1",
                                                        background: "none",
                                                        border: "none",
                                                        height: "50",
                                                        width: "50px",
                                                    }}
                                                >
                                                    <img
                                                        src={dot}
                                                        alt=""
                                                        onClick={() => {
                                                            isDropdownToggleClicked = true; // Set the flag when the dropdown toggle is clicked
                                                        }}
                                                        onMouseLeave={() => {
                                                            isDropdownToggleClicked = false; // Reset the flag when the mouse leaves the dropdown toggle
                                                        }}
                                                    />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            if (strings._language !== item.lango) {
                                                                toast.warn(`${strings.ThisAdIsCreatedIn} ${item.lango === 'en' ? 'English' :
                                                                    item.lango === 'ru' ? 'Russian' : 'Uzbek'} ${strings.PleaseChangeYourLanguageTo}
                                                                    ${item.lango === 'en' ? 'English' :
                                                                        item.lango === 'ru' ? 'Russian' : 'Uzbek'
                                                                    }, ${strings.thenYouCanEdit}
                                                                    `)
                                                            } else {
                                                                navigate("/edit-realestate", {
                                                                    state: {
                                                                        realEstateData: item,
                                                                        newAdd: false,
                                                                        basicInfo: {
                                                                            category:
                                                                                item.basicInformation.category
                                                                                    .title,
                                                                            brand:
                                                                                item.basicInformation.brand
                                                                                    .title,
                                                                        },
                                                                    },
                                                                });
                                                            }

                                                            isDropdownToggleClicked = true;
                                                        }}
                                                        onMouseLeave={() => {
                                                            isDropdownToggleClicked = false; // Reset the flag when the mouse leaves the dropdown toggle
                                                        }}
                                                    >
                                                        {strings.editAd}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setShowModal(true);
                                                            setDeletingItemId(item._id);
                                                            isDropdownToggleClicked = true;
                                                        }}
                                                        onMouseLeave={() => {
                                                            isDropdownToggleClicked = false; // Reset the flag when the mouse leaves the dropdown toggle
                                                        }}
                                                    >
                                                        {strings.delete}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setShowModal(true);
                                                            setDeletingItemId(item._id);
                                                            isDropdownToggleClicked = true;
                                                        }}
                                                        onMouseLeave={() => {
                                                            isDropdownToggleClicked = false; // Reset the flag when the mouse leaves the dropdown toggle
                                                        }}
                                                    >
                                                        {strings.enableDisable}
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div
                                            className="px-[10px] mt-1 "
                                        >
                                            <div
                                                className="flex items-center justify-between -mb-2"
                                            >

                                                <div>
                                                    <h3
                                                        className="font-[500] "
                                                        style={{
                                                            fontSize: "12px",
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        {item.basicInformation.title}
                                                    </h3>
                                                </div>
                                                <div
                                                    className="flex "
                                                    style={{
                                                        color: "#F37521",
                                                        fontSize: "12px",
                                                    }}
                                                >
                                                    <h1 className="font-bold text-sm ">
                                                        {item.basicInformation.price.currency}&nbsp;
                                                    </h1>
                                                    <h1 className="font-bold text-sm ">
                                                        {item.basicInformation.price.price}
                                                    </h1>
                                                </div>
                                            </div>
                                            <div
                                                className="flex gap-2 -mb-3"
                                            >
                                                <h1 className="text-sm text-gray-500 ">
                                                    {item.basicInformation.description.slice(
                                                        0,
                                                        15
                                                    )}
                                                </h1>
                                            </div>
                                            <div
                                                className="flex -mb-1 text-[10px] justify-between items-center text-gray-400"
                                            >
                                                <div className="flex gap-2 items-center">
                                                    <img
                                                        src={beds}
                                                        alt="year"
                                                        className="h-3 w-3"
                                                    />
                                                    <p className="text- pt-3">
                                                        {item.basicInformation.details
                                                            .filter((item) =>
                                                                [
                                                                    "Bedrooms",
                                                                    "Спальни",
                                                                    "Yotoq xonalari",
                                                                ].includes(item.fieldName)
                                                            )
                                                            .map((filteredItem, index) => (
                                                                <span key={index}>
                                                                    {filteredItem.value === ""
                                                                        ? 1
                                                                        : filteredItem.value}
                                                                </span>
                                                            ))}
                                                    </p>
                                                </div>

                                                <div className="flex gap-1 items-center">
                                                    <img
                                                        src={area}
                                                        alt="year"
                                                        className="h-3 w-3"
                                                    />
                                                    <p className="text- pt-3">
                                                        {item.basicInformation.area.net}sq.ft
                                                    </p>
                                                </div>

                                                <div className="flex gap-1 items-center">
                                                    <img
                                                        src={wash}
                                                        alt="year"
                                                        className="h-3 w-3"
                                                    />
                                                    <p className="textm pt-3">
                                                        {item.basicInformation.details
                                                            .filter((item) =>
                                                                [
                                                                    "Bathrooms",
                                                                    "Ванные комнаты",
                                                                    "Hammomlar",
                                                                ].includes(item.fieldName)
                                                            )
                                                            .map((filteredItem, index) => (
                                                                <span key={index}>
                                                                    {filteredItem.value}
                                                                </span>
                                                            ))}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                )}
            </div>
        </div>
    )
}

export default RealEstateAds