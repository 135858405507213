import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import strings from '../utils/locals/languages';
import apiClient from '../utils/apiClient';
import { ClipLoader } from 'react-spinners';

const DeleteUserModal = ({ showDeleteConfirmation, setShowDeleteConfirmation }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        setShowDeleteConfirmation(false);
    };

    const handleDelete = async () => {
        setIsLoading(true);
        try {
            await apiClient.delete(`/user/delete`);
            toast.success(`${strings.accoundDeleteSuccess}`);
            handleClose();
        } catch (error) {
            toast.error(`${strings.accoundDeleteFailed}`);
            setIsLoading(false);
        }
    };


    return (
        <Modal
            size="md"
            show={showDeleteConfirmation}
            onHide={handleClose}
            centered
            backdrop="static" // Prevents clicking outside to close
            keyboard={false}  // Disables 'Esc' key for closing
        >
            <Modal.Body className="p-10 mb-3 text-center">
                {!isLoading && <div className="text-xl mb-3">{strings.deleteAccount}</div>}
{strings.areYouSureDeleteAcc}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button onClick={handleClose} style={{backgroundColor: '#F07B34'}}>
                    {strings.no}
                </Button>
                <Button variant="danger" onClick={handleDelete} disabled={isLoading}>
                    {isLoading ? <ClipLoader size={18} color='#F07B34' /> : strings.yes}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteUserModal;
