import React from 'react'
import Edit from './Edit'
import AppBar from "../../components/AppBar";

const EditVehicle = () => {
  return (
    <>
      <AppBar id='' createPage='' />
      <Edit />
    </>
  )
}

export default EditVehicle