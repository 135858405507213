import React from 'react'
import Policy from './Terms'
import AppBar from "../../components/AppBar";

const TermsOfUse = () => {
  return (
    <>
    <AppBar id='' createPage='' />
    <Policy />
    </>
  )
}

export default TermsOfUse