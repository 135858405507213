import React from 'react'
import MyProfile from './Profile'
import AppBar from "../../components/AppBar";
import strings from '../../utils/locals/languages';
import Footer from '../../components/Footer';

const ProfilePage = () => {
  return (
    <div className="container flex flex-col min-h-screen">
      <AppBar id='' createPage='' />

      <div className='flex-grow'>
      <MyProfile />

      </div>


      <div>
      <Footer strings={strings} />

      </div>

    </div>
  )
}

export default ProfilePage