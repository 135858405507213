import React from 'react'
import strings from '../utils/locals/languages'

const NotificationsConvas = ({ notificationData }) => {
    return (
        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header bg-primary text-white">
                <h5 className="offcanvas-title" id="offcanvasExampleLabel">{strings.notif}</h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body p-3">
                {
                    notificationData?.map((data, index) => (
                        <div key={index} className={`p-3 mb-3 rounded-lg shadow-sm ${index % 2 === 0 ? "bg-blue-50" : "bg-gray-50"}`}>
                            <div className="d-flex align-items-start">
                                <div className="me-3 text-primary">
                                    <i className="bi bi-bell-fill" style={{ fontSize: '1.5rem' }}></i>
                                </div>
                                <div>
                                    <p className="fw-bold mb-1" style={{ color: '#333' }}>{data.title}</p>
                                    <p className="text-muted mb-0" style={{ fontSize: '0.9rem' }}>{data.message}</p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default NotificationsConvas
