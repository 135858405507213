import React from "react";
import Body from "./Body";
import { useParams } from "react-router-dom";
import AppBar from "../../components/AppBar";


const SearchVehicle = () => {
  const { id } = useParams();

  return (
    <div>
      <AppBar id="638f1fd34cb7c29ec6ad4bd6" createPage='/create-vehicle-ad' />
      <Body id={id} />
    </div>
  );
};

export default SearchVehicle;
