import axios from 'axios';
import Cookies from 'js-cookie';
import { baseURL1 } from './baseUrl';

const apiClient = axios.create({
	baseURL: baseURL1,
	headers: { Authorization: `Bearer ${Cookies.get('token')}` },
});

export default apiClient;
