import React, { useState, useEffect } from 'react'
import facebook from "../../images/facebook.svg";
import Google from "../../images/google.svg";
import twitter from "../../images/twitter.svg";
import instagram from "../../images/instagram.svg";
import paragraph from "../../images/paragraph.svg";
import phone from "../../images/phone.svg";
import sms from "../../images/sms.svg";
import axios from 'axios';
import { baseURL1 } from '../../utils/baseUrl';
import { setLanguage } from "../../app/languageSlice";
import { useDispatch } from "react-redux";

import strings from "../../utils/locals/languages";
import Cookies from "js-cookie";

import { useNavigate } from "react-router-dom";

import { ClipLoader } from 'react-spinners';
import apiClient from '../../utils/apiClient';
import moment from 'moment';

const Terms = () => {
  const [profileData, setProfileData] = useState({})
  const [notificationData, setNotificationData] = useState([])
  const [notificationCount, setNotificationCount] = useState(0)

  const [data, setDate] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const [menu1Open, setMenu1Open] = useState(false);
  const button1Ref = React.useRef(null);
  const button2Ref = React.useRef(null);
  // event handlers for opening and closing each menu
  const handleMenu1Open = (event) => {
    setMenu1Open(true);
  };
  const handleMenu1Close = () => {
    setMenu1Open(false);
  };
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleLanguageChange = (language) => {
    strings.setLanguage(language); // set the language for the LocalizedStrings instance

    Cookies.set("language", language);
    setTimeout(() => {
      window.location.reload();
    }, 500);
    dispatch(setLanguage(language)); // dispatch an action to update the language in the store
  };

  useEffect(() => {
    const selectedLanguage = Cookies.get("language")

    if (selectedLanguage !== undefined) {
      strings.setLanguage(selectedLanguage)
    }

  }, [])

  const logOutUser = () => {
    Cookies.remove("token");
    Cookies.remove("userDetails");
    window.location.reload();
  };

  useEffect(() => {
    axios.get(`${baseURL1}/v1/user/term-of-use`)
      .then((res) => {
        setDate(res.data.termsOfUse)
        setIsLoading(false)
      })
      .catch((err) => {
      })
  }, [])

  useEffect(() => {
    apiClient
      .get(
        `/v1/user/notification`
      )
      .then((response) => {
        setNotificationCount(response.data.length)
        setNotificationData(response.data)
      })
      .catch((err) => { });
  }, [])

  useEffect(() => {
    const userDetails = Cookies.get("userDetails")
    if (userDetails) {
      const user = JSON.parse(userDetails);
      const {
        name,
        companyName,
        phone,
        email,
        country,
        city,
        logo,
        address,
        image,
        taxNumber,
        officePhone,
        gender,
      } = user;
      const filteredUser = {
        name,
        companyName,
        phone,
        email,
        country,
        city,
        logo,
        address,
        image,
        taxNumber,
        officePhone,
        gender
      };
      setProfileData(filteredUser);
    } else {
      setProfileData('Null')
    }

  }, [])
  return (
    <>

      <div className="container privacy-policy mt-5">
        <div className="row">
          <div className="col-12">
            <h3 className='font-base text-semibold text-center'>{strings.termsOfUse}</h3>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        {
          isLoading ? (
            <div className="container flex justify-center">
              <div>
                <ClipLoader
                  loading={isLoading}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            </div>
          ) : (
            data.map((item) => (
              <div className="row">
                <div className="col-12">
                  <h5 className="text-[#F37521] text-sm ">{item.title}</h5>
                  <p className="text-justify text-xs">
                    {item.text}.{" "}
                  </p>
                </div>
              </div>
            ))
          )
        }


      </div>

      {/*footer*/}
      <div className="container mx-auto  mt-5 ">
        <div className=" bg-gray-200 grid lg:grid-cols-3    md:grid-cols-3  sm:grid-cols-1 px-5 pt-4">
          <div className="sm:px-5 md:px-5 lg:md-5">
            <h6 className="px-5">Contact Us</h6>
            <div className="flex justify-center">
              <img className="me-3" src={paragraph} alt="" />
              <p style={{ fontSize: "12px", marginTop: "20px" }}>
                Lorem ipsum dolor sit amet, Cras pretium, consectetur adipiscing
                elit.
              </p>
            </div>

            <div className="flex">
              <img className="w-6" src={phone} alt="" />
              <div className="mt-2 ">
                <p className="px-3 ">(+92-433943444)</p>
                <p className="px-3">(+92-433943444)</p>
              </div>
            </div>

            <div className="flex">
              <img className="sm:w-6 md:w-6 lg:w-6" src={sms} alt="" />
              <div className="mt-2">
                <p className="px-3">esols@gmail.com</p>
                <p className="px-3">esols@gmail.com</p>
              </div>
            </div>
          </div>
          <div className="px-5 ">
            <h6>Quick Links</h6>
            <p className="mt-4">Home</p>
            <p>Real State</p>
            <p>Vehicles</p>
            <p>About Us</p>
          </div>
          <div className="px-5">
            <h6>Follow Us</h6>

            <div className="flex gap-2 mt-4">
              <img className="w-8 " src={facebook} alt="" />

              <img className="w-8 " src={Google} alt="" />
              <img className="w-8 " src={twitter} alt="" />
              <img className="w-8 " src={instagram} alt="" />
            </div>
          </div>
        </div>

        <div className="pb-2 bg-gray-200 container mx-auto">
          <h6 className="text-center   sm:text-center md:text-center  sm:text-sm">
            © {moment().format('YYYY')} All rights reserved. Created by Lorem Ipsum
          </h6>
        </div>
      </div>

      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasExampleLabel">{strings.notif}</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          {
            notificationData.map((data, index) => (
              <div key={index}
                className={`p-2 ${index % 2 === 0 ? "bg-gray-300" : ""
                  }`}
              >
                <p style={{ fontWeight: 'bold' }}>{index + 1}. {data.title}</p>
                <p>{data.message}</p>
              </div>
            ))
          }
        </div>

      </div>
    </>
  );
}

export default Terms