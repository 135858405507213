import React, { useEffect } from "react";
import Body from "./Body";
import AppBar from "../../components/AppBar";
import { useLocation } from "react-router-dom";
const Vehicle = () => {
  const location = useLocation();
  const id = location.state.id;


  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <div>
      <AppBar id={id} createPage='/create-vehicle-ad' />
      <Body id={id} />
    </div>
  );
};

export default Vehicle;
