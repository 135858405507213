import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import dot from "../../images/3dot.svg";
import Modal from "react-bootstrap/Modal";
import DataSaverOffIcon from '@mui/icons-material/DataSaverOff';
import { baseURL1 } from "../../utils/baseUrl";
import apiClient from "../../utils/apiClient";
import { ClipLoader } from "react-spinners";
import Cookies from "js-cookie";
import strings from "../../utils/locals/languages";
import area from "../../images/ar.svg";
import beds from "../../images/bedss.svg";
import wash from "../../images/wshs.svg";
import year from "../../images/yeear.svg";
import engineSize from "../../images/engine.svg";
import calendar from "../../images/calendar.svg";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import AddHomeIcon from "@mui/icons-material/AddHome";
import NotificationsConvas from "../../components/NotificationsCanvas";
import RealEstateAds from "./RealEstateAds";
import VehicleAds from "./VehicleAds";
import { useSelector } from "react-redux";

const AdMangement = () => {
  const notifications = useSelector(state => state.notifications.notifications);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [deletingItemId, setDeletingItemId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [status, setStatus] = useState("")

  const userDetails = Cookies.get("userDetails");
  const user = JSON.parse(userDetails);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  const navigate = useNavigate();


  const deleteAd = () => {
    setDeleteLoader(true);
    apiClient
      .delete(`/v1/delete-adds/${deletingItemId}`)
      .then((res) => {
        setDeleteLoader(false);
        toast.success("Ad Deleted Successfully!");
      })
      .catch(() => {
        setDeleteLoader(false);
        toast.error("Failed to delete the ad!");
      });
  };

  const enableDisableAd = () => {
    setDeleteLoader(true);
    apiClient
      .put(`/v1/adds/enable-disable-adds/${deletingItemId}?type=disable`)
      .then((res) => {
        setDeleteLoader(false);
        toast.success("Ad Status changed Successfully!");
      })
      .catch(() => {
        setDeleteLoader(false);
        toast.error("Failed to change ad Status!");
      });
  };

  const [isLoading, setIsLoading] = useState(true);
  const [vehicleData, setVehicleData] = useState([]);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);

    setEdit(true);
    setAddManuallyItem(false);
  };

  const handleShow = () => setShow(true);
  const [edit, setEdit] = useState(false);
  const [addmanuallyitem, setAddManuallyItem] = useState(false);
  const [section, setSection] = useState("realEstate");

  useEffect(() => {
    setIsLoading(true);
    apiClient
      .get(
        `${baseURL1}/v1/adds/get-user-ads?language=${strings._language}&section=638f1fd34cb7c29ec6ad4bd6`
      )
      .then((response) => {
        setVehicleData(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => { });;

    apiClient
      .get(
        `${baseURL1}/v1/adds/get-user-ads?language=${strings._language}&section=638f25154cb7c29ec6ad4bfe`
      )
      .then((response) => {
        setData(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => { });;
  }, [strings._language, section]);
 
  return (
    <>
      <div className="container mx-auto">
        <h3 className="text-center uppercase font-bold text-lg">
          {strings.AdManagement}
        </h3>
      </div>

      <div className="container">
        <div className="container flex flex-col md:flex-row lg:flex-row gap-2 justify-end">
          <div className="custom  mt-3 w- h-[40px] bg-transparent flex items-center pt-[6 gap-1 px-3 itemce rounded-full  border-1 border-black bor  bg-white text-center">
            <ImportExportIcon style={{ height: "18px", width: "18px" }} />
            <select
              className=" text-sm font-semibold outline-none bg-transparent tracking-wide"
              onChange={(e) => {
                setSortBy(e.target.value);
              }}
              value={sortBy}
            >
              <option value="">{strings.sortBy}</option>
              <option value="ph">{strings.priceHighest}</option>
              <option value="pl">{strings.priceLowest}</option>
              <option value="new">{strings.newest}</option>
              <option value="old">{strings.oldFirst}</option>
              <option value="atoz">{strings.alpAZ}</option>
              <option value="ztoa">{strings.alpZA}</option>
            </select>
          </div>

          <div className="custom  mt-3 w- h-[40px] bg-transparent flex items-center pt-[6 gap-1 px-3 itemce rounded-full  border-1 border-black bor  bg-white text-center">
            <AddHomeIcon
              className="h-4 w-4"
              style={{ height: "18px", width: "18px" }}
            />
            <select
              className=" text-sm font-semibold outline-none bg-transparent tracking-wide"
              onChange={(e) => {
                setSection(e.target.value);
              }}
              value={section}
            >
              <option value="realEstate">{strings.realEstates}</option>
              <option value="vehicle">{strings.vehicles}</option>
            </select>
          </div>

          <div className="custom  mt-3 w- h-[40px] bg-transparent flex items-center pt-[6 gap-1 px-3 itemce rounded-full  border-1 border-black bor  bg-white text-center">
            <DataSaverOffIcon style={{ height: "18px", width: "18px" }} />
            <select
              className=" text-sm font-semibold outline-none bg-transparent tracking-wide"
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              value={status}
              style={
                {
                  // fontSize: 18,
                }
              }
            >
              <option value="">{strings.status}</option>
              <option value="approved">{strings.active}</option>
              <option value="inactive">{strings.inActive}</option>
              <option value="featured">{strings.featured}</option>
              <option value="expired">{strings.expired}</option>
              <option value="pending">{strings.pending}</option>
              <option value="rejected">{strings.rejected}</option>
            </select>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="container flex justify-center align-middle mt-5 ">
          <div>
            <ClipLoader
              loading={isLoading}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </div>
      ) : (
        <>
          {section === "realEstate" ? (
            <RealEstateAds
              data={data}
              user={user}
              sortBy={sortBy}
              navigate={navigate}
              Dropdown={Dropdown}
              dot={dot}
              Cookies={Cookies}
              setShowModal={setShowModal}
              setDeletingItemId={setDeletingItemId}
              beds={beds}
              area={area}
              wash={wash}
              status={status}

            />
          ) : (
            <VehicleAds
              vehicleData={vehicleData}
              user={user}
              sortBy={sortBy}
              navigate={navigate}
              Dropdown={Dropdown}
              dot={dot}
              Cookies={Cookies}
              setShowModal={setShowModal}
              setDeletingItemId={setDeletingItemId}
              beds={beds}
              area={area}
              wash={wash}
              status={status}
              year={year}
              engineSize={engineSize}
              calendar={calendar}
            />
          )}
        </>
      )}


      <Modal
        size="md"
        style={{ marginTop: "50px" }}
        show={showModal}
        onHide={handleCloseModal}
      >
        <div className="p-3">
          <h3>{strings.areYouSure}</h3>
          <Button
            className="mt-3 bg-orange-600 text-white shadow-md rounded-full"
            onClick={enableDisableAd}
          >
            {deleteLoader
              ? strings.loading
              : `${strings.yes}, ${strings.delete}`}
          </Button>
        </div>
      </Modal>

      <NotificationsConvas notificationData={notifications} />
    </>
  );
};

export default AdMangement;