import React from 'react'
import AppBar from '../../components/AppBar'
import Form from './Form'

const ReportAd = () => {
  return (
    <div>
            <AppBar id='' createPage='' />
            <Form />
    </div>
  )
}

export default ReportAd