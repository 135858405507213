import { configureStore } from '@reduxjs/toolkit';
import languageReducer from '../app/languageSlice';
import notificationsReducer from '../app/notificationsSlice';

const store = configureStore({
  reducer: {
    language: languageReducer,
    notifications: notificationsReducer
  },
});

export default store;
