import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";

const useAuth = () => {
    const User = Cookies.get('token')

    if (User === null || User === undefined) {
        const user = { loggedIn: false };
        return user && user.loggedIn;
    }
    else {
        const user = { loggedIn: true };
        return user && user.loggedIn;
    }

};

const ProtectedRoutes = () => {
    const isAuth = useAuth();
    return isAuth ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;