import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/Group 3321.svg";
import NavbarImage1 from "../../images/Group 11680.png";
import NavbarImage2 from "../../images/Group 11819.png";
import NavbarImage3 from "../../images/gil.svg";
import { setLanguage } from "../../app/languageSlice";
import { useDispatch } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import strings from "../../utils/locals/languages";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { useFormik } from "formik";
import Footer from "../../components/Footer";
import apiClient from "../../utils/apiClient";
import { toast } from "react-toastify";
import Avatar from "@mui/material/Avatar";

const ReportProblem = () => {
  const [profileData, setProfileData] = useState({});
  const location = useLocation();
  const [notificationData, setNotificationData] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [imageUrl, setImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const [menu1Open, setMenu1Open] = useState(false);
  const button1Ref = useRef(null);
  const button2Ref = useRef(null);
  const hiddenFileInput = useRef(null);

  const handleInputClick = (event) => {
    hiddenFileInput.current.click();
  };

  const uploadImage = (File) => {
    setImageLoading(true);
    const formData = new FormData();
    formData.append("files", File);

    apiClient
      .post(`/v1/adds/upload-multiple`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setImageLoading(false);
        setImageUrl(res.data[0]);
        toast.success(`${strings.imageUpload}`);
      })
      .catch((err) => {
        toast.error(`${strings.imageUploadFailed}`);
      });
  };

  // event handlers for opening and closing each menu
  const handleMenu1Open = (event) => {
    setMenu1Open(true);
  };
  const handleMenu1Close = () => {
    setMenu1Open(false);
  };
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleLanguageChange = (language) => {
    strings.setLanguage(language); // set the language for the LocalizedStrings instance

    Cookies.set("language", language);
    setTimeout(() => {
      window.location.reload();
    }, 500);
    dispatch(setLanguage(language)); // dispatch an action to update the language in the store
  };

  useEffect(() => {
    const selectedLanguage = Cookies.get("language");

    if (selectedLanguage !== undefined) {
      strings.setLanguage(selectedLanguage);
    }
  }, []);

  const submitData = (data) => {
    setIsLoading(true);
    apiClient
      .post("/v1/user//report-suggestion", {
        detail: data,
        imageUrl,
      })
      .then((res) => {
        setIsLoading(false);
        toast.success(`${strings.reportAdded}`);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(`${strings.failedToSubmitReport}`);
      });
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      submitData(formik.values.detail);
    },
  });
  useEffect(() => {
    const userDetails = Cookies.get("userDetails");
    const user = JSON.parse(userDetails);
    const {
      name,
      companyName,
      phone,
      email,
      country,
      city,
      logo,
      address,
      image,
      taxNumber,
      officePhone,
      detail,
    } = user;
    const filteredUser = {
      name,
      companyName,
      phone,
      email,
      country,
      city,
      logo,
      address,
      image,
      taxNumber,
      officePhone,
    };

    formik.setValues(filteredUser);
  }, []);

  const logOutUser = () => {
    Cookies.remove("token");
    Cookies.remove("userDetails");
    window.location.reload();
  };

  const getNotifications = async () => {
    try {
      const response = await apiClient.get("/v1/user/notification")
      setNotificationCount(response.data.length);
      setNotificationData(response.data);
    } catch (err) {

    }

  }

  useEffect(() => {
    getNotifications()
  }, []);

  useEffect(() => {
    const userDetails = Cookies.get("userDetails");
    if (userDetails) {
      const user = JSON.parse(userDetails);
      const {
        name,
        companyName,
        phone,
        email,
        country,
        city,
        logo,
        address,
        image,
        taxNumber,
        officePhone,
        gender,
      } = user;
      const filteredUser = {
        name,
        companyName,
        phone,
        email,
        country,
        city,
        logo,
        address,
        image,
        taxNumber,
        officePhone,
        gender,
      };
      setProfileData(filteredUser);
    } else {
      setProfileData("Null");
    }
  }, []);

  return (
    <>
      <div className="container mx-auto">
        <h6 className="text-center font-semibold text-lg uppercase">
          {strings.reportProb}
        </h6>
      </div>
      <div className="container mx-auto mt-5">
        <h6 className=" text-[14px] font-semibold">
          {strings.user} {strings.info}
        </h6>
      </div>

      <div className="container mx-auto text-xs">
        <div className="md:w-64 w-[100%]   flex justify-between mt-3">
          <p>{strings.name}</p>
          <spanr>{formik.values.name}</spanr>
        </div>

        <div className="md:w-64 w-[100%]  flex justify-between mt-1">
          <p>{strings.email}</p>
          <spanr>{formik.values.email}</spanr>
        </div>
      </div>

      <div className="container mx-auto mt-3">
        <h6 className="text-[14px] font-semibold">
          {strings.write} {strings.details}
        </h6>

        <div>
          <textarea
            className="bg-white p-4 pt-3 rounded-lg h-32 mt-2 text-sm text-gray-400 shadow-md md:w-64 w-[100%] lg:w-[500px] md:w-[500px] "
            style={{
              fontWeight: 100, // Adjust the font weight as needed (e.g., 100 is lighter than 200)
            }}
            onChange={(e) => {
              formik.setFieldValue("detail", e.target.value);
            }}
            value={formik.values.detail}
          >
            {strings.desc}
          </textarea>
        </div>
      </div>

      <div className="container mx-auto mt-4">
        {
           imageUrl === '' ? (
            <h6 className="text-[14px] font-semibold">
            {strings.add} {strings.screenshot}
          </h6>
           ) : <div className="w-[20px] h-[20px] cursor-pointer" onClick={() => setImageUrl('')}>X</div>
         
        }

        {
          imageUrl !== '' ? (
            <img src={imageUrl} />
          ) : (
            <div className="bg-white p-3 grid grid:cols-1 justify-items-center rounded-lg h-32 mt-4 shadow-md md:w-64 w-[100%] lg:w-[500px] md:w-[500px] ">
              <h6 className=" text-sm text-gray-500"> Add Image</h6>
              <input
                type="file"
                style={{ display: "none" }}
                ref={hiddenFileInput}
                onChange={(e) => {
                  uploadImage(e.target.files[0]);
                }}
              />
              <div className="flex justify-center">
                <button
                  className="bg-orange-500 text-white w-32 h-10 text-sm rounded-xl mt-1"
                  onClick={handleInputClick}
                >
                  {imageLoading ? strings.loading : strings.upload}
                </button>
              </div>

            </div>
          )
        }
      </div>

      <div className="container mx-auto mt-12">
        <div className="flex justify-center">
          <button
            className="bg-orange-500 text-white font-semibold md:w-64 w-[100%] lg:w-80 md:w-72 p-3 rounded-full"
            onClick={formik.handleSubmit}
          >
            {isLoading ? strings.loading : strings.submit}
          </button>
        </div>
      </div>

      {/*footer*/}

      <Footer strings={strings} />
      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasExampleLabel">
            {strings.notif}
          </h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          {notificationData.map((data, index) => (
            <div
              key={index}
              className={`p-2 ${index % 2 === 0 ? "bg-gray-300" : ""}`}
            >
              <p style={{ fontWeight: "bold" }}>
                {index + 1}. {data.title}
              </p>
              <p>{data.message}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ReportProblem;
