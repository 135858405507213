import React, { useState, useEffect } from 'react'
import PackageCard from './Card';
import apiClient from '../../utils/apiClient';
import { ClipLoader } from 'react-spinners';
import strings from '../../utils/locals/languages';


const Plans = () => {

    const [userPackagesLoading, setUserPackagesLoading] = useState(true)
    const [userPackages, setUserPackages] = useState([])
    const [standardPackageLoading, setStandardPackageLoading] = useState(true)
    const [standardPackage, setStandardPackage] = useState(null)
    const [packagesLoading, setPackagesLoading] = useState(true)
    const [packages, setPackages] = useState([])

    const [remainingAds, setRemainingAds] = useState(0)
    const [freeAds, setFreeAds] = useState(0)

    const getStandardPackage = async () => {
        try {
            const response = await apiClient.get(`/v1/user/get-standard-package?language=${strings._language}`)

            setStandardPackage(response.data.package)
            setStandardPackageLoading(false)
        } catch {
            setStandardPackage(null)
        }
    }

    const getUserPackages = async () => {
        try {
            const response = await apiClient.get(`/v1/user/get-my-packages`)

            setUserPackages(response.data.user.packages)
            setRemainingAds(response.data.user.totalRemainingAds)
            setFreeAds(response.data.user.freeAds)
            setUserPackagesLoading(false)
        } catch {
            setUserPackages([])
        }
    }

    const getPackages = async () => {
        try {
            const response = await apiClient.get(`/v1/user/get-packages`)

            setPackages(response.data.packages)
            setPackagesLoading(false)
        } catch {
            setUserPackages([])
        }
    }

    useEffect(() => {
        getStandardPackage()
        getUserPackages()
        getPackages()
    }, [])

    return (
        <div className="flex flex-col p-6">
            <h2 className='mb-6 text-3xl font-bold'>{strings.pleaseSelectPlan}</h2>
            <h1 className="text-2xl">{strings.totalAdsRemaining} <span className='text-[#F37521]' style={{fontWeight: 'bold'}}>{remainingAds}</span></h1>
            <h1 className="text-2xl">{strings.freeAdsRemaining} <span className='text-[#F37521]' style={{fontWeight: 'bold'}}>{freeAds}</span></h1>
           

            {/* <div className="flex flex-wrap gap-6">
                {
                    standardPackageLoading ? <ClipLoader /> : (
                        <div className="bg-gray-100 shadow-lg h-[300px] w-80 rounded-md flex flex-col items-center ">
                            <div className="bg-[rgb(243,117,33)] rounded-t-lg text-white w-full text-center py-2 text-lg font-bold">Standard Package</div>
                            <div className="flex-1 my-4 w-[90%] h-[270px] text-base">{standardPackage.text.slice(0, 80)}</div>
                            <div className="text-xl flex w-[90%] justify-between mb-2">
                                <div>Price</div>
                                <div>{standardPackage.currency}{standardPackage.price}</div>
                            </div>
                            <button className="bg-[#F37521] text-white py-2 px-4 rounded-full mb-4 mt-4 hover:bg-[#D3621E]">Buy</button>
                        </div>
                    )
                }
            </div> */}

            <h3 className='mb-6 text-lg font-bold '>{strings.boughtPackages}</h3>
            {
                userPackagesLoading ? (
                    <ClipLoader />
                ) : (
                    userPackages.length === 0 ? (
                        <div className='text-sm'>{strings.noPackageFound}</div>
                    ) : (
                        <div className="flex flex-wrap gap-6">
                            {userPackages.map((pkg, index) => (
                                <PackageCard key={index} package={pkg} buy={false} onClick={false} />
                            ))}
                        </div>
                    )
                )
            }


            <h3 className='mb-6 text-lg font-bold mt-5'>{strings.otherPackages}</h3>
            <div className="flex flex-wrap gap-6">
                {
                    packagesLoading ? (
                        <ClipLoader />
                    ) : (
                        packages.length === 0 ? (
                            <div className='text-sm'>{strings.noPackageFound}</div>
                        ) : (
                            <div className="flex flex-wrap gap-6">
                                {packages.map((pkg, index) => (
                                    <PackageCard key={index} package={pkg} buy={true} onClick={true} />
                                ))}
                            </div>
                        )
                    )
                }
            </div>
        </div>
    )
}

export default Plans