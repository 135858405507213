import React, { useEffect, useState } from 'react';
import AppBar from '../../components/AppBar';
import Plans from './Plans';
import Footer from '../../components/Footer';
import strings from '../../utils/locals/languages';

const SelectPackage = () => {
  const [vehicleData, setVehicleData] = useState(null);
  const [isEditOrCreate, setIsEditOrCreate] = useState('');

  useEffect(() => {
    const storedVehicleData = sessionStorage.getItem('vehicleData');
    const storedIsEditOrCreate = sessionStorage.getItem('isEditOrCreate');

    if (storedVehicleData) {
      setVehicleData(JSON.parse(storedVehicleData));
    }

    if (storedIsEditOrCreate) {
      setIsEditOrCreate(storedIsEditOrCreate);
    }
  }, []);

  return (
    <div className="container flex flex-col min-h-screen">
      <AppBar />
      <div className='flex-grow'>
        <Plans isEditOrCreate={isEditOrCreate} data={vehicleData} />
      </div>

      <Footer strings={strings} />
    </div>
  );
};

export default SelectPackage;
