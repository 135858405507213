import React from 'react';
import AdManagement from './AdManagement';
import AppBar from '../../components/AppBar';
import Footer from '../../components/Footer';
import strings from '../../utils/locals/languages';

const About = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <AppBar id='' createPage='' />
      <div className="flex-1">
        <AdManagement />
      </div>
      <Footer strings={strings} className="mt-auto" />
    </div>
  );
};

export default About;
