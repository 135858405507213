import React, {useEffect, useState} from "react";
import Body from "./Body";
// import Header from "./Header";
import Header from '../../components/Header'

const Home = () => {

  const [search, setSearch] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
      <Header search={search} setSearch={setSearch} />
      <Body search={search} />
    </div>
  );
};

export default Home;
